module.exports = global.config = {

    POOl_SERVICE_CHARGE_PERCENT_UPTO_99: 10,
    POOl_SERVICE_CHARGE_PERCENT_BETWEEN_100_AND_499: 5,
    POOl_SERVICE_CHARGE_PERCENT_MORE_THAN_499: 25,
    MINIMUM_POOL_FEE: 8,
    AppName:"ChipinPool",  

    //DEVELOPMENT START

    // API_URL: "https://localhost:444/api/v1/chipinpool/",
    // BASE_API_URL: "https://localhost:444",
    // SOCKET_URL: 'https://localhost:444/chat',
    // PAYPAL_CLIENT_ID: "AZpo5m0giHfuvnNYNfqAT9pFtnuV8iWTvbAXt7DFJRky8EmW9FGgK0sXjcPVbRLd81Lykkb_Ti22B8Cm",
    // PAYPAL_MERCHANT_ID: "BSMQY5XM4G8RQ",
    // PAYPAL_COMPONENT: ["buttons"],
    // APP_ENV: 'development',

    //DEVELOPMENT END

    //PRODUCTION START

    API_URL: "https://app.chipinpool.com/api/v1/chipinpool/",
    BASE_API_URL: "https://app.chipinpool.com",
    SOCKET_URL: 'https://app.chipinpool.com/chat',
    PAYPAL_CLIENT_ID: "AbqkMhsl9vYvXK1JPRLJ8DESEc2k49M1qwNLhwvElg3ptFtd-udk-5pQgIvJINl8IjVfIwlg-ca5ii35",
    PAYPAL_MERCHANT_ID: "H9QDWJP234JFE",
    PAYPAL_COMPONENT: ["buttons", "applepay"],
    APP_ENV: 'production',

    //PRODUCTION END




};