import classes from "./poolDetails.module.css";
import React, { useState, useEffect, useCallback, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import Moment from "moment";

import {
    Navigate,
    useNavigate,
    Link,
    useSearchParams,
    useLocation,
    json,
} from "react-router-dom";
import NavigationBar from "../../components/navigation/navigation";
import PoolService from "../../services/pool.service";
import EventBus from "../../common/eventBus";
import { renderToString } from "react-dom/server";
import { loadScript } from "@paypal/paypal-js";
import { convertUTCDateToLocalDate } from "../../helpers/dateTimeHelper";
import * as Yup from "yup";
import QRCode from "react-qr-code";
import Multiselect from "multiselect-react-dropdown";
import useMediaQuery from "../../hooks/useMediaQuery";
import { clearMessage, setMessage } from "../../slices/message";
import "../../../config";
import Switch from "react-switch";
import {
    CircularProgressbarWithChildren,
    CircularProgressbar,
    buildStyles,
} from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { CopyToClipboard } from "react-copy-to-clipboard";
import UserService from "../../services/user.service";
import { setProgress } from "../../slices/loader";
import { getPoolFinacialActivities, setPoolSelected } from "../../slices/pool";
import { setRoomSelected } from "../../slices/chat";
import { getAccountByUserId } from "../../slices/user";
import { v4 as uuid } from "uuid";
import Chat from "../../components/chat/chat";
import { ReactTags } from "react-tag-autocomplete";
import ManagePaymentService from "../../services/managePayment.service";
import useIsVisible from "../../hooks/useIsVisible";
import { moneyFormatter } from "../../common/money";
import "./poolDetails.css";


const PoolDetails = (props) => {
    const searchParams = useLocation();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const { progress } = useSelector((state) => state.loader);
    const { user: currentUser } = useSelector((state) => state.auth);
    const { user: account } = useSelector((state) => state.user);
    const { message } = useSelector((state) => state.message);
    const { selectedPoolFinacialActivities } = useSelector((state) => state.pool);
    const { rooms } = useSelector((state) => state.chat);

    const PoolID = new URLSearchParams(searchParams.search).get("id");
    const Action = new URLSearchParams(searchParams.search).get("action");
    const Referral = new URLSearchParams(searchParams.search).get("referral");


    const PAYPAL_CLIENT_ID = global.config.PAYPAL_CLIENT_ID;
    const PAYPAL_MERCHANT_ID = global.config.PAYPAL_MERCHANT_ID;
    const PAYPAL_COMPONENT = global.config.PAYPAL_COMPONENT;

    const leaveAlertModalRef = useRef(null);
    const joinAlertModalRef = useRef(null);


    const [reload, setReload] = useState(false);

    const [pool, setPool] = useState({});
    const [poolStatus, setPoolStatus] = useState(1);

    const [amountInvested, setAmountInvested] = useState(0);
    const [goalPercentage, setGoalPercentage] = useState(0);

    const [inviteContacts, setInviteContacts] = useState([]);
    const [inviteStatus, setInviteStatus] = useState("");
    const [selectedContacts, setSelectedContacts] = useState([]);

    const [joiningAmount, setJoiningAmount] = useState(0);
    const [contributionAmount, setContributionAmount] = useState(0);

    const [discount, setDiscount] = useState(0);
    const [paymentMessage, setPaymentMessage] = useState({});

    const [amountValidation, setAmountValidation] = useState("");
    const [deleteRequests, setDeleteRequests] = useState([]);

    const [isBusyInviteContacts, setIsBusyInviteContacts] = useState(false);



    { /* PAYMENT SOURCE  START */ }

    const [paypal, setPaypal] = useState({});
    const [applepay, setApplepay] = useState({});
    const [applepayConfig, setApplePayConfig] = useState({});

    useEffect(() => {
        (async function () {
            try {
                let _paypal = await loadScript({
                    ["client-id"]: PAYPAL_CLIENT_ID,
                    currency: "USD",
                    ["merchant-id"]: [PAYPAL_MERCHANT_ID],
                    components: PAYPAL_COMPONENT,
                });

                setPaypal(_paypal);

                if (PAYPAL_COMPONENT.filter((x) => x === "applepay").length > 0) {
                    let _applePay = _paypal.Applepay();
                    setApplepay(_applePay);

                    let _appleConfig = await _applePay.config();
                    setApplePayConfig(_appleConfig);
                }
            } catch (error) {
                console.log("failed to load the PayPal JS SDK script", error);
            }
        })();
    }, []);

    {/* APPLE PAY START */ }

    const createApplePayOrder = useCallback(async () => {
        let result = {};
        try {
            await ManagePaymentService.createPaypalOrder(
                contributionAmount,
                joiningAmount,
                PoolID,
                discount,
                'POOL'
            ).then(
                (response) => {
                    if (response) {
                        result = response.data.response_body.Order;
                    }
                },
                (error) => {
                    throw error;
                }
            );
        } catch (err) {
            throw err;
        }

        return result.id;
    }, [joiningAmount]);

    const onApplePayApprove = useCallback(async (id) => {

        await ManagePaymentService.capturePaypalOrder(
            id,
            PoolID,
            currentUser ? currentUser.user.id : null,
            'POOL').then(
                async (response) => {
                    if (response) {

                        setPaymentMessage({
                            successStatus: true,
                            message: "Payment Successful"
                        });

                        setReload(true)
                    }
                },
                (error) => {
                    const _content =
                        (error.response &&
                            error.response.data &&
                            error.response.data.response_message) ||
                        error.message ||
                        error.toString();

                    setPaymentMessage({
                        successStatus: false,
                        message: _content
                    })
                }
            );

    }, []);

    const ApplePayButton = () => {
        const containerDiv = useRef();

        useEffect(() => {
            if (containerDiv.current) {
                if (!window.ApplePaySession) {
                    console.log("This device does not support Apple Pay");
                    return;
                }
                if (!window.ApplePaySession.canMakePayments()) {
                    console.log(
                        "This device is not capable of making Apple Pay payments"
                    );
                    return;
                }

                containerDiv.current.innerHTML =
                    '<apple-pay-button id="btn-appl" buttonstyle="black" type="buy" locale="en">';

                document
                    .getElementById("btn-appl")
                    .addEventListener("click", handeApplePayment);
            }
        }, [amountValidation, pool, containerDiv.current]);

        return (
            <>
                <div ref={containerDiv}></div>
            </>
        );
    };

    const handeApplePayment = () => {
        if (!applepayConfig.isEligible) {
            console.log("applepay is not eligible");
            return;
        }

        const paymentRequest = {
            countryCode: applepayConfig.countryCode,
            merchantCapabilities: applepayConfig.merchantCapabilities,
            supportedNetworks: applepayConfig.supportedNetworks,
            currencyCode: applepayConfig.currencyCode,
            requiredShippingContactFields: [
                "name",
                "phone",
                "email",
                "postalAddress",
            ],
            requiredBillingContactFields: ["postalAddress"],
            total: {
                label: `${pool.name}`,
                type: "final",
                amount: joiningAmount.toString(),
            },
        };

        let session = new window.ApplePaySession(4, paymentRequest);

        // Merchant Validation
        session.onvalidatemerchant = async function (event) {
            applepay
                .validateMerchant({
                    validationUrl: event.validationURL,
                    displayName: `${pool.name}`,
                })
                .then((validateResult) => {
                    session.completeMerchantValidation(validateResult.merchantSession);
                })
                .catch((validateError) => {
                    console.error(validateError);
                    session.abort();
                });
        };

        session.onpaymentmethodselected = function (event) {
            session.completePaymentMethodSelection({
                newTotal: paymentRequest.total,
            });
        };

        session.onpaymentauthorized = async function (event) {
            try {
                let orderId = await createApplePayOrder();

                await applepay.confirmOrder({
                    orderId: orderId,
                    token: event.payment.token,
                    billingContact: event.payment.billingContact,
                    shippingContact: event.payment.shippingContact,
                });

                await onApplePayApprove(orderId);

                session.completePayment({
                    status: window.ApplePaySession.STATUS_SUCCESS,
                });
            } catch (err) {
                console.error(err);
                session.completePayment({
                    status: window.ApplePaySession.STATUS_FAILURE,
                });
            }
        };

        session.oncancel = function (event) { };

        session.begin();
    };

    {/* APPLE PAY END */ }

    {/* PAYPAL START */ }

    const createPayPalOrder = useCallback(async () => {
        let result = {};
        try {
            await ManagePaymentService.createPaypalOrder(
                contributionAmount,
                joiningAmount,
                PoolID,
                discount,
                'POOL'
            ).then(
                (response) => {
                    if (response) {
                        result = response.data.response_body.Order;
                    }
                },
                (error) => {
                    throw error;
                }
            );
        } catch (err) {
            throw err;
        }
        return result.id;
    }, [joiningAmount]);

    const onPayPalApprove = useCallback(async (order) => {
        await ManagePaymentService.capturePaypalOrder(
            order.orderID,
            PoolID,
            currentUser ? currentUser.user.id : null,
            'POOL').then(
                async (response) => {
                    if (response) {

                        setPaymentMessage({
                            successStatus: true,
                            message: "Payment Successful"
                        });
                        setReload(true)
                    }
                },
                (error) => {
                    const _content =
                        (error.response &&
                            error.response.data &&
                            error.response.data.response_message) ||
                        error.message ||
                        error.toString();

                    setPaymentMessage({
                        successStatus: false,
                        message: _content
                    })
                }
            );

    }, []);

    const PayPalButtons = () => {
        const containerDiv = useRef();

        useEffect(() => {
            if (paypal) {
                if (containerDiv.current) {
                    (async function () {
                        await paypal
                            .Buttons({
                                createOrder: createPayPalOrder,
                                onApprove: onPayPalApprove,
                            })
                            .render(containerDiv.current);
                    })();
                }
            }
        }, [paypal, amountValidation, pool, containerDiv.current]);

        return (
            <>
                <div ref={containerDiv}></div>
            </>
        );
    };

    {/* PAYPAL END */ }

    {/* PAYMENT SOURCE END */ }


    useEffect(() => {

        dispatch(setProgress(progress + 50));

        Promise.all(currentUser ? [
            PoolService.getPoolsById(PoolID),
            PoolService.getDeleteRequestByPoolId(PoolID),
            dispatch(getPoolFinacialActivities({ id: PoolID })),
            dispatch(getAccountByUserId({})),
        ] : [
            PoolService.getPoolsById(PoolID),
            PoolService.getDeleteRequestByPoolId(PoolID),
            dispatch(getPoolFinacialActivities({ id: PoolID }))
        ]
        ).then(async (response) => {
            dispatch(setProgress(100));

            if (response[0].data.response_body.Pool) {

                dispatch(setPoolSelected(response[0].data.response_body.Pool));

                await setPageDetails(response[0].data.response_body.Pool);
            }
            if (response[1].data.response_body.Requests) {
                setDeleteRequests(response[1].data.response_body.Requests);
            }

        }).catch((error) => {
            const _content =
                (error.response &&
                    error.response.data &&
                    error.response.data.response_message) ||
                error.message ||
                error.toString();

            dispatch(setMessage(_content));
            dispatch(setProgress(100));
            if (error.response && error.response.status === 401) {
                EventBus.dispatch("logout");
            }
            if (error.response && error.response.status === 500) {
                EventBus.dispatch("logout");
            }
        });

    }, [dispatch, reload]);


    useEffect(() => {

        if (Action === 'invited') {

            joinAlertModalRef.current.click();

        }

    }, [Action]);


    {/* METHODS START */ }

    /// @name setPageDetails
    /// 
    /// 
    /// @param {any} pool - 
    async function setPageDetails(pool) {
        // START POOL DETAILS       

        setPool(pool);
        setPoolStatus(pool.status == 0 ? false : true);
        // END POOL DETAILS

        // START GOAL PERCENTAGE
        let totalAmountInvested = pool.PoolsMembers.reduce(
            (accumulator, current) => {
                return accumulator + parseFloat(current.contributed);
            },
            0
        );
        setAmountInvested(totalAmountInvested);
        let amountPercentage = calculatePercentage(
            parseFloat(totalAmountInvested),
            parseFloat(pool.goal_amount)
        );
        setGoalPercentage(amountPercentage);
        // END GOAL PERCENTAGE


        // START  CONTRIBUTION AMOUNT
        let amount = pool.defaultBuy_in_amount;
        setContributionAmount(amount);

        if (currentUser) {

            if (account && account.email_verified && account.email_verified === 1) {
                amount = await getFinalContributionAmount(amount);
            }

        }

        setJoiningAmount(parseFloat(amount));
        // END  CONTRIBUTION AMOUNT     
    }

    /// @name calculatePercentage
    /// 
    /// 
    /// @param {any} partialValue - 
    /// @param {any} totalValue - 
    /// @returns {any} - 
    function calculatePercentage(partialValue, totalValue) {
        return (100 * partialValue) / totalValue;
    }

    /// @name getFinalContributionAmount
    /// 
    /// 
    /// @param {any} amount - 
    /// @returns {any} - 
    async function getFinalContributionAmount(amount) {
        let result = parseFloat(amount);

        await ManagePaymentService.getFinalContributionAmount(currentUser.user.id, amount).then(
            (response) => {
                if (response.data.response_body.ContributionAmount) {
                    result = response.data.response_body.ContributionAmount.amount;
                    setDiscount(response.data.response_body.ContributionAmount.discount);
                }
            },
            (error) => {
                const _content =
                    (error.response &&
                        error.response.data &&
                        error.response.data.response_message) ||
                    error.message ||
                    error.toString();

                if (error.response && error.response.status === 401) {
                    EventBus.dispatch("logout");
                }
                if (error.response && error.response.status === 500) {
                    EventBus.dispatch("logout");
                }
            }
        );

        return result;
    }


    /// @name validateJoiningAmount
    /// 
    /// 
    /// @param {any} amount - 
    async function validateJoiningAmount(amount) {
        setContributionAmount(parseFloat(amount))
        if (amount === "") {
            setAmountValidation("chip in amount cannot be empty.");
        } else if (parseFloat(amount) <= 0) {
            setAmountValidation("chip in amount must be greater than zero.");
        } else if (parseFloat(amount) < parseFloat(global.config.MINIMUM_POOL_FEE)) {
            setAmountValidation(
                "chip in amount ($" +
                amount +
                ") must be greater than or equal to minium amount."
            );
        }
        else {

            if (currentUser) {
                amount = await getFinalContributionAmount(amount);
            }

            setAmountValidation("");
            setJoiningAmount(parseFloat(amount));
        }
    };


    /// @name debounce
    /// 
    /// 
    /// @param {any} fn - 
    /// @param {number} delay [100] - 
    /// @returns {any} - 
    function debounce(fn, delay = 100) {
        let timeoutID;

        return function (...args) {
            clearTimeout(timeoutID);
            timeoutID = setTimeout(() => fn(...args), delay);
        };
    }

    {/* METHODS END */ }


    {/* EVENTS START */ }

    const handleSendInvite = (id) => {
        dispatch(setProgress(progress + 50));
        let recepients = selectedContacts.map((x) => {
            return x.value;
        });
        PoolService.inviteNotification(recepients, id).then(
            (response) => {
                setInviteStatus("Invite sent !");
                dispatch(setProgress(100));
            },
            (error) => {
                const _content =
                    (error.response &&
                        error.response.data &&
                        error.response.data.response_message) ||
                    error.message ||
                    error.toString();

                dispatch(setMessage(_content));
                dispatch(setProgress(100));

                if (error.response && error.response.status === 401) {
                    EventBus.dispatch("logout");
                }
                if (error.response && error.response.status === 500) {
                    EventBus.dispatch("logout");
                }
            }
        );

        setSelectedContacts([]);
        setInviteContacts([]);
    };

    const handlePoolDelete = () => {
        dispatch(setProgress(progress + 50));
        PoolService.createPoolDeleteRequest(pool.id, currentUser.user.id).then(
            (response) => {
                if (response) {
                    setDeleteRequests([
                        ...deleteRequests,
                        response.data.response_body.Request,
                    ]);
                }
                dispatch(setProgress(100));
            },
            (error) => {
                const _content =
                    (error.response &&
                        error.response.data &&
                        error.response.data.response_message) ||
                    error.message ||
                    error.toString();

                dispatch(setMessage(_content));
                dispatch(setProgress(100));

                if (error.response && error.response.status === 401) {
                    EventBus.dispatch("logout");
                }
                if (error.response && error.response.status === 500) {
                    EventBus.dispatch("logout");
                }
            }
        );
    };

    const handlePayout = () => {
        return navigate("/payout?id=" + pool.id);
    };

    const handleRefund = () => {
        return navigate("/pool-refund?id=" + pool.id);
    };

    const handlePoolsStatusUpdate = (e) => {
        dispatch(setProgress(progress + 50));
        PoolService.updatePoolsStatus(pool.id, e ? 1 : 0).then(
            (response) => {
                if (response) {
                    let status = response.data.response_body.Bettingpool.status;
                    setPoolStatus(status > 0 ? true : false);
                    setPool(response.data.response_body.Bettingpool);
                }
                dispatch(setProgress(100));
            },
            (error) => {
                const _content =
                    (error.response &&
                        error.response.data &&
                        error.response.data.response_message) ||
                    error.message ||
                    error.toString();

                dispatch(setMessage(_content));
                dispatch(setProgress(100));

                if (error.response && error.response.status === 401) {
                    EventBus.dispatch("logout");
                }
                if (error.response && error.response.status === 500) {
                    EventBus.dispatch("logout");
                }
            }
        );
    };

    const handleJoin = (PoolID) => {
        PoolService.addMemberToPool(currentUser.user.id, PoolID).then(
            (response) => {

                setReload(true);
            },
            (error) => {
                const _content =
                    (error.response &&
                        error.response.data &&
                        error.response.data.response_message) ||
                    error.message ||
                    error.toString();

                if (error.response && error.response.status === 401) {
                    EventBus.dispatch("logout");
                }
                if (error.response && error.response.status === 500) {
                    EventBus.dispatch("logout");
                }
            }
        );
    };

    const handleLeaveJoinedPool = (PoolID) => {
        let contributedAmount =
            pool.PoolsMembers.filter(
                (member) => member.memberID === currentUser.user.id
            ).length > 0
                ? pool.PoolsMembers.filter(
                    (member) => member.memberID === currentUser.user.id
                )[0].contributed
                : 0;

        if (parseFloat(contributedAmount) == 0) {
            PoolService.leavePools(currentUser.user.id, PoolID).then(
                (response) => {
                    setPool(response.data.response_body.Pool);
                },
                (error) => {
                    const _content =
                        (error.response &&
                            error.response.data &&
                            error.response.data.response_message) ||
                        error.message ||
                        error.toString();

                    if (error.response && error.response.status === 401) {
                        EventBus.dispatch("logout");
                    }
                    if (error.response && error.response.status === 500) {
                        EventBus.dispatch("logout");
                    }
                }
            );
        } else {
            leaveAlertModalRef.current.click();
        }
    };

    const handleMakeMemberAdmin = (PoolID, memberID) => {
        PoolService.makeMemberAdmin(PoolID, memberID).then(
            (response) => {
                setPool(response.data.response_body.BettingPool);
            },
            (error) => {
                const _content =
                    (error.response &&
                        error.response.data &&
                        error.response.data.response_message) ||
                    error.message ||
                    error.toString();

                if (error.response && error.response.status === 401) {
                    EventBus.dispatch("logout");
                }
                if (error.response && error.response.status === 500) {
                    EventBus.dispatch("logout");
                }
            }
        );
    };

    const onAddInviteContacts = useCallback(
        (newTag) => {
            setSelectedContacts([...selectedContacts, newTag]);
            setInviteContacts([]);
        },
        [selectedContacts]
    );

    const onDeleteInviteContacts = useCallback(
        (index) => {
            setSelectedContacts(selectedContacts.filter((_, i) => i !== index));
        },
        [selectedContacts]
    );

    const onInputInviteContacts = useCallback(
        debounce(async (query) => {
            if (isBusyInviteContacts) return;

            setIsBusyInviteContacts(true);

            let response = await UserService.searchUsers(currentUser.user.id, query)
                .then()
                .finally(() => {
                    setIsBusyInviteContacts(false);
                });

            let suggestions = response.data.response_body.Users;
            setInviteContacts(suggestions);
        }),
        [isBusyInviteContacts]
    );

    {/* EVENTS END */ }



    return (
        <>
            <main>
                <div className="cip-dashboard-body">
                    <NavigationBar props={{ type: 0, pageName: "Pool Details" }} />

                    {Object.keys(pool).length > 0 && pool.PoolsMembers.length > 0 && (
                        <div className="pool-details-area">
                            <div className="pool-topbar d-flex justify-content-between">
                                <div className="back-button-area d-flex align-items-center">
                                    <button onClick={() => navigate(-1)}>
                                        <svg
                                            width="20"
                                            height="20"
                                            viewBox="0 0 20 20"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path
                                                d="M15.8333 10H4.16666"
                                                strokeWidth="1.5"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                            />
                                            <path
                                                d="M9.99999 15.8332L4.16666 9.99984L9.99999 4.1665"
                                                strokeWidth="1.5"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                            />
                                        </svg>
                                    </button>
                                    <h2 className="text-title-1 m-0">Pool Details</h2>
                                </div>
                                <div className="pool-buttons-area">
                                    {Object.keys(pool).length > 0 &&
                                        currentUser &&
                                        pool.PoolsPermissions.filter(
                                            (x) =>
                                                x.memberID == currentUser.user.id &&
                                                (x.roleID == 1 || x.roleID == 2)
                                        ).length > 0 && (
                                            <label>
                                                <span>Close</span>&nbsp;
                                                <Switch
                                                    onChange={(e) => handlePoolsStatusUpdate(e)}
                                                    checked={poolStatus}
                                                />
                                                <span>Open</span>&nbsp;
                                            </label>
                                        )}

                                    {pool.status > 0 && (
                                        <>
                                            {Object.keys(pool).length > 0 &&
                                                currentUser &&
                                                pool.PoolsPermissions.filter(
                                                    (x) =>
                                                        x.memberID == currentUser.user.id &&
                                                        (x.roleID == 1 || x.roleID == 2)
                                                ).length > 0 && (
                                                    <a
                                                        href="#"
                                                        className="edit-pool"
                                                        onClick={() => navigate("/pool-edit?id=" + pool.id)}
                                                    >
                                                        <svg
                                                            width="17"
                                                            height="17"
                                                            viewBox="0 0 17 17"
                                                            fill="none"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                        >
                                                            <path
                                                                d="M8.5 1H2.66667C2.22464 1 1.80072 1.17559 1.48816 1.48816C1.17559 1.80072 1 2.22464 1 2.66667V14.3333C1 14.7754 1.17559 15.1993 1.48816 15.5118C1.80072 15.8244 2.22464 16 2.66667 16H14.3333C14.7754 16 15.1993 15.8244 15.5118 15.5118C15.8244 15.1993 16 14.7754 16 14.3333V8.5"
                                                                stroke="#67717C"
                                                                strokeWidth="2"
                                                                strokeLinecap="round"
                                                                strokeLinejoin="round"
                                                            ></path>
                                                            <path
                                                                d="M14.1791 0.483986C14.489 0.174095 14.9093 -4.61774e-09 15.3476 0C15.7858 4.61774e-09 16.2061 0.174095 16.516 0.483986C16.8259 0.793877 17 1.21418 17 1.65243C17 2.09068 16.8259 2.51099 16.516 2.82088L9.11586 10.221L6 11L6.77896 7.88414L14.1791 0.483986Z"
                                                                fill="#67717C"
                                                            ></path>
                                                        </svg>
                                                    </a>
                                                )}
                                            {
                                                (currentUser) && (
                                                    <button
                                                        className="invite-member text-title-4"
                                                        data-bs-toggle="modal"
                                                        data-bs-target="#invitePopUp"
                                                    >
                                                        Invite Member
                                                        <svg
                                                            width="20"
                                                            height="20"
                                                            viewBox="0 0 20 20"
                                                            fill="none"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                        >
                                                            <path
                                                                d="M10 4.16675V15.8334"
                                                                stroke="#00582E"
                                                                strokeWidth="1.5"
                                                                strokeLinecap="round"
                                                                strokeLinejoin="round"
                                                            />
                                                            <path
                                                                d="M4.16666 10H15.8333"
                                                                stroke="#00582E"
                                                                strokeWidth="1.5"
                                                                strokeLinecap="round"
                                                                strokeLinejoin="round"
                                                            />
                                                        </svg>
                                                    </button>
                                                )
                                            }

                                        </>
                                    )}

                                    <button
                                        className="btn btn-outline-primary"
                                        onClick={handlePayout}
                                    >
                                        Payout
                                        <svg
                                            width="20"
                                            height="20"
                                            viewBox="0 0 20 20"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path
                                                d="M10 4.16675V15.8334"
                                                stroke="#00582E"
                                                strokeWidth="1.5"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                            />
                                            <path
                                                d="M4.16666 10H15.8333"
                                                stroke="#00582E"
                                                strokeWidth="1.5"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                            />
                                        </svg>
                                    </button>
                                    {
                                        (currentUser) &&
                                        (
                                            <button
                                                className="btn btn-outline-primary" onClick={handleRefund}
                                            >
                                                Refund
                                                <svg
                                                    width="20"
                                                    height="20"
                                                    viewBox="0 0 20 20"
                                                    fill="none"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                >
                                                    <path
                                                        d="M10 4.16675V15.8334"
                                                        stroke="#00582E"
                                                        strokeWidth="1.5"
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                    />
                                                    <path
                                                        d="M4.16666 10H15.8333"
                                                        stroke="#00582E"
                                                        strokeWidth="1.5"
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                    />
                                                </svg>
                                            </button>
                                        )
                                    }


                                    {Object.keys(pool).length > 0 &&
                                        currentUser &&
                                        pool.status == 1 &&
                                        pool.PoolsPermissions.length >= 0 &&
                                        pool.PoolsPermissions.filter(
                                            (x) => x.memberID == currentUser.user.id
                                        ).length == 0 && (
                                            <button
                                                className="invite-member"
                                                style={{ width: "66px", border: "none" }}
                                                onClick={() => handleJoin(pool.id)}
                                            >
                                                Join
                                            </button>
                                        )}

                                    {Object.keys(pool).length > 0 &&
                                        currentUser &&
                                        pool.status == 1 &&
                                        pool.PoolsPermissions.length >= 0 &&
                                        pool.PoolsPermissions.filter(
                                            (x) => x.memberID == currentUser.user.id
                                        ).length > 0 &&
                                        pool.PoolsPermissions.filter(
                                            (x) => x.memberID == currentUser.user.id && x.roleID == 1
                                        ).length == 0 &&
                                        pool.PoolsPermissions.filter(
                                            (x) => x.memberID == currentUser.user.id && x.roleID == 2
                                        ).length == 0 && (
                                            <button
                                                className="btn btn-primary"
                                                style={{ width: "90px", border: "none" }}
                                                onClick={() => handleLeaveJoinedPool(pool.id)}
                                            >
                                                Leave
                                            </button>
                                        )}
                                </div>
                            </div>
                            {
                                (!currentUser) &&
                                (
                                    <div className="alert alert-primary" role="alert">
                                        Login to use your credits benefits when contributing.
                                    </div>
                                )
                            }
                            {
                                (account &&
                                    account.email_verified === 0 &&
                                    (<div className="alert alert-info alert-dismissible fade show" role="alert">
                                        <strong>Email not verified!</strong> Please verify email to use your credits benefits when contributing.
                                        <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                                    </div>))
                            }

                            {
                                (paymentMessage) && (paymentMessage.successStatus) &&
                                ((paymentMessage.successStatus === true) && (<div className="alert alert-success alert-dismissible fade show" role="alert">
                                    <strong>Success!</strong> {paymentMessage.message}
                                    <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close">
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>)) ||
                                ((paymentMessage.successStatus === false) && (<div className="alert alert-danger alert-dismissible fade show" role="alert">
                                    <strong>Failed!</strong> {paymentMessage.message}
                                    <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close">
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>))
                            }

                            <div className="pool-details-content">
                                <div className="pool-details-thumbnail">
                                    <img
                                        src={
                                            pool.photo
                                                ? pool.photo
                                                : "assets/img/pool-thumbnails/pool-details.png"
                                        }
                                        alt="pool-thumbnail"
                                    />
                                </div>
                                <div className="pool-information">
                                    <h2 className="text-headline-1 m-0">{pool.name}</h2>
                                    <div className="pool-data-container">
                                        <div className="pool-data-item">
                                            <p className="text-title-3">Type</p>
                                            <p>
                                                {
                                                    ((Object.keys(pool).length > 0)) && (pool.is_private == 1) && (
                                                        <span className="badge bg-warning" style={{ height: 'fit-content', marginRight: 'auto', marginLeft: '7px', marginTop: '5px' }}>Private</span>
                                                    )
                                                }
                                                {
                                                    ((Object.keys(pool).length > 0)) && (pool.is_private == 0) && (
                                                        <span className="badge bg-warning" style={{ height: 'fit-content', marginRight: 'auto', marginLeft: '7px', marginTop: '5px' }}>Public</span>
                                                    )
                                                }
                                            </p>
                                        </div>
                                        <div className="pool-data-item">
                                            <p className="text-title-3">Default buy in amount</p>
                                            <p className="text-body-2">
                                                ${pool.defaultBuy_in_amount}
                                            </p>
                                        </div>
                                        <div className="pool-data-item">
                                            <p className="text-title-3">Goal amount</p>
                                            <p className="text-body-2">${pool.goal_amount}</p>
                                        </div>
                                        <div className="pool-data-item">
                                            <p className="text-title-3">Total Amount contributed</p>
                                            <p className="text-body-2">
                                                {pool.PoolsMembers.length > 0
                                                    ? "$" +
                                                    pool.PoolsMembers.reduce((accumulator, current) => {
                                                        return (
                                                            accumulator + parseFloat(current.contributed)
                                                        );
                                                    }, 0)
                                                    : "$0"}
                                            </p>
                                        </div>
                                        {currentUser && (
                                            <div className="pool-data-item">
                                                <p className="text-title-3">Amount contributed (Me)</p>
                                                <p className="text-body-2">
                                                    {pool.PoolsMembers.filter(
                                                        (member) => member.memberID === currentUser.user.id
                                                    ).length > 0
                                                        ? "$" +
                                                        pool.PoolsMembers.filter(
                                                            (member) =>
                                                                member.memberID === currentUser.user.id
                                                        )[0].contributed
                                                        : "$0"}
                                                </p>
                                            </div>
                                        )}
                                        <div className="pool-data-item">
                                            <p className="text-title-3">Status</p>
                                            <p className="text-body-2">
                                                {pool.status !== 1 ? 'Close' : 'Open'}
                                            </p>
                                        </div>
                                        <div className="pool-data-item">
                                            <p className="text-title-3">Created Date</p>
                                            <p className="text-body-2">
                                                {Moment(
                                                    convertUTCDateToLocalDate(new Date(pool.createdAt))
                                                ).format("MMMM Do YYYY, h:mm:ss a")}
                                            </p>
                                        </div>
                                        <div className="pool-data-item">
                                            <p className="text-title-3">Description</p>
                                            <p className="text-body-2">{pool.description}</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="pool-fund-area">
                                    <div className="pool-fund-progress-area">
                                        <CircularProgressbarWithChildren
                                            value={goalPercentage}
                                            styles={buildStyles({
                                                pathColor:
                                                    pool.goal_amount <=
                                                        (pool.PoolsMembers.length > 0
                                                            ? pool.PoolsMembers.reduce(
                                                                (accumulator, current) => {
                                                                    return (
                                                                        accumulator +
                                                                        parseFloat(current.contributed)
                                                                    );
                                                                },
                                                                0
                                                            )
                                                            : 0)
                                                        ? `rgb(0,128,0)`
                                                        : `rgba(62, 152, 199, ${goalPercentage / 100})`,
                                            })}
                                        >
                                            <div style={{ marginTop: -5 }}>
                                                <h2>${amountInvested}</h2>
                                            </div>
                                            <p>${pool.goal_amount}</p>
                                        </CircularProgressbarWithChildren>
                                    </div>
                                    <div>
                                        <h3>Fee Summary</h3>
                                        <div className="row">
                                            <table style={{ margin: "10px" }}>
                                                <tbody>
                                                    <tr key={0}>
                                                        <td>Chip In Amount</td>
                                                        <td>
                                                            <div
                                                                className="input-group"
                                                                style={{
                                                                    padding: "0px",
                                                                    width: "60%",
                                                                    float: "right",
                                                                }}
                                                            >
                                                                <div className="input-group-prepend">
                                                                    <span className="input-group-text">$</span>
                                                                </div>
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    style={{ padding: "0px", textAlign: 'right' }}
                                                                    defaultValue={contributionAmount}
                                                                    onChange={(event) =>
                                                                        validateJoiningAmount(event.target.value)
                                                                    }
                                                                />

                                                            </div>
                                                        </td>
                                                    </tr>
                                                    {
                                                        (currentUser)
                                                        && (
                                                            <tr key={1}>
                                                                <td>Credit Discount</td>
                                                                <td style={{ textAlign: 'right' }}>
                                                                    <span>${discount} Off</span>
                                                                </td>
                                                            </tr>
                                                        )
                                                    }
                                                    <tr style={{ borderTop: '1px solid gray' }} key={2}>
                                                        <td>Total</td>
                                                        <td style={{ textAlign: 'right' }}>
                                                            <label style={{ fontWeight: "bolder" }}>
                                                                ${joiningAmount}
                                                            </label>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                                <div className="pool-fund-area" style={{ margin: 'auto' }}>

                                    {amountValidation && (
                                        <div className="alert alert-danger" role="alert">
                                            {amountValidation}
                                        </div>
                                    )}

                                    {pool.status === 0 && (
                                        <div className="alert alert-danger" role="alert">
                                            Contribution feature is not allowed in closed pool.
                                        </div>
                                    )}

                                    {!amountValidation && pool.status > 0 && (
                                        <div className="row">
                                            {paypal && (
                                                <>
                                                    <PayPalButtons />
                                                    <ApplePayButton />
                                                </>
                                            )}
                                        </div>
                                    )}

                                </div>
                            </div>
                            <br></br>
                            <div className="table-responsive">
                                <table className="table">
                                    <thead className="thead-light">
                                        <tr>
                                            <th scope="col">Name</th>
                                            <th scope="col">Amount Paid Total</th>
                                            <th scope="col">Joining At</th>
                                            <th scope="col"></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {pool.PoolsMembers.length > 0 &&
                                            pool.PoolsMembers.map(function (nextItem, j) {
                                                return (
                                                    <tr key={nextItem.id}>
                                                        <td>
                                                            <Link to={"/profile?id=" + nextItem.User.id}>
                                                                {nextItem.User.firstName +
                                                                    " " +
                                                                    nextItem.User.lastName}
                                                            </Link>{" "}
                                                        </td>
                                                        <td>${nextItem.contributed}</td>
                                                        <td>
                                                            {Moment(
                                                                convertUTCDateToLocalDate(
                                                                    new Date(nextItem.createdAt)
                                                                )
                                                            ).format("MMMM Do YYYY, h:mm:ss a")}
                                                        </td>
                                                        <td >
                                                            {pool.status > 0 &&
                                                                currentUser &&
                                                                pool.PoolsPermissions.filter(
                                                                    (x) =>
                                                                        x.memberID == currentUser.user.id &&
                                                                        (x.roleID == 1 || x.roleID == 2)
                                                                ).length > 0 &&
                                                                pool.PoolsPermissions.filter(
                                                                    (x) =>
                                                                        x.memberID == nextItem.memberID &&
                                                                        (x.roleID == 1 || x.roleID == 2)
                                                                ).length == 0 && (
                                                                    <button
                                                                        type="button"
                                                                        onClick={() =>
                                                                            handleMakeMemberAdmin(
                                                                                pool.id,
                                                                                nextItem.memberID
                                                                            )
                                                                        }
                                                                        className="btn btn-outline-primary btn-sm"
                                                                    >
                                                                        Make admin
                                                                    </button>
                                                                )}
                                                        </td>
                                                    </tr>
                                                );
                                            })}
                                    </tbody>
                                </table>
                            </div>
                            <br></br>
                            <div className="table-responsive">
                                <table className="table">
                                    <thead className="thead-light">
                                        <tr>
                                            <th scope="col">Name</th>
                                            <th scope="col">Amount</th>
                                            <th scope="col">Action</th>
                                            <th scope="col">Balance</th>
                                            <th scope="col">Goal</th>
                                            <th scope="col">Action Date</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {(selectedPoolFinacialActivities) &&
                                            (selectedPoolFinacialActivities.length > 0) &&
                                            selectedPoolFinacialActivities.map(function (nextItem, j) {
                                                return (
                                                    <tr key={nextItem.id}>
                                                        <td>
                                                            <Link to={"/profile?id=" + nextItem.memberID}>
                                                                {nextItem.name}
                                                            </Link>
                                                        </td>
                                                        <td>{moneyFormatter.format(nextItem.amount)}</td>
                                                        <td>{nextItem.action}</td>
                                                        <td>{moneyFormatter.format(nextItem.pool_balance)}</td>
                                                        <td>{moneyFormatter.format(nextItem.goal_amount)}</td>
                                                        <td>
                                                            {Moment(
                                                                convertUTCDateToLocalDate(
                                                                    new Date(nextItem.createdAt)
                                                                )
                                                            ).format("MMMM Do YYYY, h:mm:ss a")}
                                                        </td>
                                                    </tr>
                                                );
                                            })}
                                    </tbody>
                                </table>
                            </div>
                            <br></br>
                            {currentUser &&
                                Object.keys(pool).length > 0 &&
                                pool.PoolsPermissions.filter(
                                    (x) =>
                                        x.memberID == currentUser.user.id &&
                                        (x.roleID == 1 || x.roleID == 2)
                                ).length > 0 && (
                                    <div className="card">
                                        <div className="card-header">Delete Pool</div>
                                        <br></br>
                                        <div className="row">
                                            {deleteRequests.length == 0 && (
                                                <div style={{ textAlign: "center" }}>
                                                    <p>
                                                        Once you delete your pool, there is no going back.
                                                        Please be certain. Once you click a delete request
                                                        is generated and send to administrator.
                                                    </p>
                                                    <button
                                                        className="delete-pool text-title-4"
                                                        onClick={handlePoolDelete}
                                                        style={{ float: "right", marginRight: "20px" }}
                                                    >
                                                        Delete Pool
                                                        <svg
                                                            width="20"
                                                            height="20"
                                                            viewBox="0 0 20 20"
                                                            fill="none"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                        >
                                                            <path
                                                                d="M4 6H5.33333H16"
                                                                stroke="#7B2C26"
                                                                strokeWidth="1.5"
                                                                strokeLinecap="round"
                                                                strokeLinejoin="round"
                                                            />
                                                            <path
                                                                d="M7.33331 5.99992V4.66659C7.33331 4.31296 7.47379 3.97382 7.72384 3.72378C7.97389 3.47373 8.31302 3.33325 8.66665 3.33325H11.3333C11.6869 3.33325 12.0261 3.47373 12.2761 3.72378C12.5262 3.97382 12.6666 4.31296 12.6666 4.66659V5.99992M14.6666 5.99992V15.3333C14.6666 15.6869 14.5262 16.026 14.2761 16.2761C14.0261 16.5261 13.6869 16.6666 13.3333 16.6666H6.66665C6.31302 16.6666 5.97389 16.5261 5.72384 16.2761C5.47379 16.026 5.33331 15.6869 5.33331 15.3333V5.99992H14.6666Z"
                                                                stroke="#7B2C26"
                                                                strokeWidth="1.5"
                                                                strokeLinecap="round"
                                                                strokeLinejoin="round"
                                                            />
                                                            <path
                                                                d="M8.66669 9.33325V13.3333"
                                                                stroke="#7B2C26"
                                                                strokeWidth="1.5"
                                                                strokeLinecap="round"
                                                                strokeLinejoin="round"
                                                            />
                                                            <path
                                                                d="M11.3333 9.33325V13.3333"
                                                                stroke="#7B2C26"
                                                                strokeWidth="1.5"
                                                                strokeLinecap="round"
                                                                strokeLinejoin="round"
                                                            />
                                                        </svg>
                                                    </button>
                                                </div>
                                            )}
                                            {deleteRequests.length > 0 && (
                                                <div
                                                    className="alert alert-primary"
                                                    style={{ width: "80%", marginLeft: "20px" }}
                                                >
                                                    This pool is scheduled to be deleted.
                                                </div>
                                            )}
                                        </div>
                                        <br></br>
                                    </div>
                                )}
                        </div>
                    )}

                    {currentUser &&
                        Object.keys(pool).length > 0 &&
                        pool.PoolsMembers.length > 0 &&
                        pool.PoolsMembers.filter((x) => x.memberID == currentUser.user.id).length > 0 &&
                        (
                            <Chat
                                id={pool.id}
                                type={"POOL"}
                                sendMessage={props.sendMessage}

                            />
                        )}

                    {!currentUser && (
                        <>
                            {" "}
                            <div className="row">
                                <div
                                    className="alert alert-danger"
                                    role="alert"
                                    style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        textAlign: "center",
                                    }}
                                >
                                    Please sign in to view chat
                                </div>
                            </div>
                        </>
                    )}

                    <div className="modal fade" id="invitePopUp">
                        <div className="modal-dialog" role="document">
                            <div className="modal-body">
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <h5 className="modal-title text-headline-3 m-0">
                                            Invite Pool Members
                                        </h5>
                                        <button
                                            type="button"
                                            className="close"
                                            data-bs-dismiss="modal"
                                            aria-label="Close"
                                        >
                                            <span aria-hidden="true">&times;</span>
                                        </button>
                                    </div>
                                    <div className="pool-invitation-container">
                                        {inviteStatus.length > 0 && (
                                            <div
                                                className="alert alert-success"
                                                style={{ width: "80%", marginLeft: "20px" }}
                                            >
                                                {inviteStatus}
                                            </div>
                                        )}
                                        <div className="pool-invitation-form">
                                            <ReactTags
                                                allowNew
                                                labelText="Select characters"
                                                noOptionsText={isBusyInviteContacts && !inviteContacts.length ? "Loading..." : "No characters found"}
                                                onAdd={onAddInviteContacts}
                                                onDelete={onDeleteInviteContacts}
                                                onInput={onInputInviteContacts}
                                                placeholderText="Start typing..."
                                                selected={selectedContacts}
                                                suggestions={inviteContacts}
                                                onValidate={(value) => { return /^(\s?[^\s,]+@[^\s,]+\.[^\s,]+\s?,)*(\s?[^\s,]+@[^\s,]+\.[^\s,]+)$/.test(value) }}
                                            />
                                            <br></br>
                                            {selectedContacts.length > 0 && (
                                                <button
                                                    className="btn btn-primary"
                                                    onClick={() => handleSendInvite(pool.id)}
                                                >
                                                    Invite
                                                </button>
                                            )}
                                        </div>
                                        <div className="invitation-qr-code">
                                            <h5 className="text-title-2 text-center m-0">
                                                Invite connections below or via email.
                                            </h5>
                                            <div
                                                style={{
                                                    height: "auto",
                                                    margin: "0 auto",
                                                    maxWidth: 150,
                                                    width: "100%",
                                                }}
                                            >
                                                <QRCode
                                                    size={256}
                                                    style={{
                                                        height: "auto",
                                                        maxWidth: "100%",
                                                        width: "100%",
                                                    }}
                                                    value={
                                                        window.location.origin +
                                                        "/pool-details?action=invited&id=" +
                                                        (pool ? pool.id : '') + "&referral=" + (currentUser ? currentUser.user.referral_code : '')
                                                    }
                                                    viewBox={`0 0 256 256`}
                                                />
                                            </div>
                                            <CopyToClipboard
                                                text={
                                                    window.location.origin +
                                                    "/pool-details?action=invited&id=" +
                                                    (pool ? pool.id : '') + "&referral=" + (currentUser ? currentUser.user.referral_code : '')
                                                }
                                                onCopy={() => alert("Copied to clipboard !")}
                                            >
                                                <button className="text-title-3">
                                                    <svg
                                                        width="17"
                                                        height="16"
                                                        viewBox="0 0 17 16"
                                                        fill="none"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                    >
                                                        <path
                                                            d="M9.73039 11.5101L7.55599 13.6845C6.22949 14.9755 4.10758 14.9468 2.81655 13.6203C1.55041 12.3194 1.55013 10.247 2.81589 8.94575L4.99029 6.76935C5.25057 6.50888 5.25042 6.08674 4.98995 5.82646C4.72948 5.56618 4.30734 5.56633 4.04706 5.8268L1.87331 8.0032C0.0441827 9.83327 0.0449326 12.7996 1.87497 14.6288C3.705 16.4579 6.67137 16.4571 8.50054 14.6271L10.6749 12.4527C10.9307 12.1879 10.9233 11.7659 10.6586 11.5102C10.4003 11.2607 9.9907 11.2607 9.73239 11.5102H9.73039V11.5101Z"
                                                            fill="#C5914B"
                                                        />
                                                        <path
                                                            d="M15.1292 1.37386C14.253 0.491678 13.0602 -0.00307683 11.8169 1.65573e-05C10.5743 -0.00329555 9.38186 0.490428 8.50528 1.3712L6.32753 3.54626C6.06706 3.80654 6.06691 4.22868 6.32719 4.48915C6.58747 4.74962 7.00961 4.74977 7.27008 4.48949L9.44648 2.31509C10.0733 1.68448 10.9264 1.33092 11.8155 1.3332C13.6666 1.33383 15.1667 2.83487 15.166 4.6859C15.1657 5.5743 14.8127 6.42626 14.1846 7.05453L12.0102 9.22894C11.7497 9.4894 11.7497 9.9117 12.0102 10.1722C12.2706 10.4326 12.6929 10.4326 12.9534 10.1722L15.1278 7.99908C16.9544 6.16854 16.955 3.20508 15.1292 1.37386Z"
                                                            fill="#C5914B"
                                                        />
                                                        <path
                                                            d="M10.029 5.52792L6.02943 9.52745C5.76461 9.78321 5.7573 10.2052 6.01305 10.47C6.2688 10.7348 6.69082 10.7421 6.9556 10.4864C6.96116 10.481 6.9666 10.4756 6.97197 10.47L10.9715 6.47047C11.2273 6.20566 11.2199 5.78367 10.9551 5.52792C10.6968 5.27845 10.2873 5.27845 10.029 5.52792Z"
                                                            fill="#C5914B"
                                                        />
                                                    </svg>
                                                    Copy Link
                                                </button>
                                            </CopyToClipboard>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <button
                        type="button"
                        style={{ display: "none" }}
                        ref={leaveAlertModalRef}
                        className="btn btn-primary"
                        data-bs-toggle="modal"
                        data-bs-target="#LeaveAlertPopUp"
                    >
                        Show Leave Alert Notification
                    </button>

                    <div className="modal fade" id="LeaveAlertPopUp">
                        <div className="modal-dialog" role="document">
                            <div className="modal-body">
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <h5 className="modal-title text-headline-3 m-0">Alert</h5>
                                        <button
                                            type="button"
                                            className="close"
                                            data-bs-dismiss="modal"
                                            aria-label="Close"
                                        >
                                            <span aria-hidden="true">&times;</span>
                                        </button>
                                    </div>
                                    <div className="pool-invitation-container">
                                        You cannot leave this pool, you have contributed amount to
                                        pool.
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <button
                        type="button"
                        style={{ display: "none" }}
                        ref={joinAlertModalRef}
                        className="btn btn-primary"
                        data-bs-toggle="modal"
                        data-bs-target="#JoinAlertPopUp"
                    >
                        Show Join Alert Notification
                    </button>

                    <div className="modal fade" id="JoinAlertPopUp">
                        <div className="modal-dialog" role="document">
                            <div className="modal-body">
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <h5 className="modal-title text-headline-3 m-0">Alert</h5>
                                        <button
                                            type="button"
                                            className="close"
                                            data-bs-dismiss="modal"
                                            aria-label="Close"
                                        >
                                            <span aria-hidden="true">&times;</span>
                                        </button>
                                    </div>
                                    <div className="pool-invitation-container">
                                        {
                                            (!currentUser) && <>
                                                <p>Please login to join this pool.Once logged in Join button will be visible.</p>
                                            </>
                                        }
                                        <p>Click Join button on the upper right side in order to join this pool.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </>
    );
};

export default PoolDetails;
