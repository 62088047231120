import classes from './account.module.css';
import React, { useState, useEffect, useCallback, useRef } from 'react';
import ReactDOM from 'react-dom';
import { Navigate, useNavigate, useLocation, Link } from 'react-router-dom';
import { Formik, Field, Form, ErrorMessage, FieldArray } from "formik";
import * as Yup from "yup";
import { loadScript } from "@paypal/paypal-js";
import { renderToString } from 'react-dom/server';
import Switch from "react-switch";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { setProgress } from "../../slices/loader";
import ManagePaymentService from "../../services/managePayment.service";
import QRCode from "react-qr-code";
import Footer from '../../components/footer/footer';
import NavigationBar from '../../components/navigation/navigation';
import UserService from "../../services/user.service";
import PoolService from "../../services/pool.service";
import AuthService from "../../services/auth.service";
import { convertUTCDateToLocalDate } from '../../helpers/dateTimeHelper';
import EventBus from "../../common/eventBus";
import { clearMessage, setMessage } from "../../slices/message";
import { getAccountByUserId, getSubscription, getSubscriptionHistory, getSubscriptionPayments, setAccountDetails, setUserSubscriptions } from "../../slices/user";
import { createEmailVerificationRequest, deleteAccount, haveAccountMarkedForDeletion } from '../../slices/auth';


const Account = () => {
    const dispatch = useDispatch();
    const searchParams = useLocation();
    const profileRef = useRef();
    const { user: currentUser, accountDeleteRequests } = useSelector((state) => state.auth);
    const { user: account, userSubscriptions, userSubscriptionHistory, userSubscriptionPayment } = useSelector((state) => state.user);
    const { progress } = useSelector((state) => state.loader);
    const { message } = useSelector((state) => state.message);

    const PAYPAL_CLIENT_ID = global.config.PAYPAL_CLIENT_ID;
    const PAYPAL_MERCHANT_ID = global.config.PAYPAL_MERCHANT_ID;

    const [paypal, setPaypal] = useState({});
    const [loading, setLoading] = useState(false);
    const [pageMessage, setPageMessage] = useState("");
    const [accountDelete, setAccountDelete] = useState("");
    const [myTransactions, setMyTransactions] = useState([]);

    const [file, setFile] = useState();
    const [photoUrl, setPhotoUrl] = useState("");
    const [plans, setPlans] = useState([]);
    const [subscriptionsSuccess, setSubscriptionsSuccess] = useState(false);
    const [userProjects, setUserProjects] = useState([]);
    const [userReferrals, setUserReferrals] = useState([]);

    const [socialMediaInitialValues, setSocialMediaInitialValues] = useState({
        paramLists: [
            {
                link: '',
                social_media: 'Twitter'
            },
            {
                link: '',
                social_media: 'Facebook'
            },
            {
                link: '',
                social_media: 'Youtube'
            },
            {
                link: '',
                social_media: 'Vimeo'
            },
            {
                link: '',
                social_media: 'Instagram'
            },
            {
                link: '',
                social_media: 'LinkedIn'
            },
            {
                link: '',
                social_media: 'Pinterest'
            }
        ]
    });

    const [userSettings, setUserSettings] = useState({
        notification_email: false,
        notification_sms: false
    });



    useEffect(() => {
        (async function () {
            try {
                let initPaypal = await loadScript(
                    {
                        ["client-id"]: PAYPAL_CLIENT_ID,
                        currency: "USD",
                        ["merchant-id"]: [PAYPAL_MERCHANT_ID],
                        components: ["buttons"],
                        intent: "subscription",
                        vault: true,
                    });

                setPaypal(initPaypal);

            } catch (error) {
                console.error("failed to load the PayPal JS SDK script", error);
            }
        })();
    }, []);

    const PaypalAppButton = (item) => {

        const payButtonRef = useRef();

        useEffect(() => {

            if (payButtonRef.current) {
                (async function () {

                    await paypal.Buttons({
                        createSubscription(data, actions) {
                            return actions.subscription.create({
                                plan_id: item.props.paypalPlanId,
                                custom_id: currentUser.user.id,
                            });
                        },
                        onApprove(data) {
                            handlePlanSubscription(data, item.props.id, item.props.price);
                        }
                    }).render(payButtonRef.current);

                })();
            }

        }, [payButtonRef.current]);

        return (<>
            <div ref={payButtonRef}></div>
        </>);
    }

    const handleAccountUpdate = (formValue) => {
        const { firstName, lastName, date_of_birth, phone } = formValue;

        setLoading(true);

        var photo = file ? file.id : null;

        dispatch(setProgress(progress + 50));
        UserService.updateAccountByUserId(currentUser.user.id, firstName, lastName, date_of_birth, phone, photo).then(
            (response) => {
                setLoading(false);
                let user = response.data.response_body.Account;
                if (user) {
                    setAccountDetails(user);
                }
            },
            (error) => {
                const _content =
                    (error.response &&
                        error.response.data &&
                        error.response.data.response_message) ||
                    error.message ||
                    error.toString();
                setLoading(false);
                dispatch(setMessage(_content));
                dispatch(setProgress(100));

                if (error.response && error.response.status === 401) {
                    EventBus.dispatch("logout");
                }
                if (error.response && error.response.status === 500) {
                    EventBus.dispatch("logout");
                }

            }
        );
    }

    const handleUserPayPalDetails = (formValue) => {

        const { payout_email_address, payout_payer_id } = formValue;

        dispatch(setProgress(progress + 50));
        ManagePaymentService.updateReceivePaymentDetails(currentUser.user.id, payout_email_address, payout_payer_id).then(
            (response) => {

                setPageMessage("Updated successfully");
                dispatch(setProgress(100));
            },
            (error) => {
                const _content =
                    (error.response &&
                        error.response.data &&
                        error.response.data.response_message) ||
                    error.message ||
                    error.toString();

                dispatch(setMessage(_content));
                dispatch(setProgress(100));

                if (error.response && error.response.status === 401) {
                    EventBus.dispatch("logout");
                }
                if (error.response && error.response.status === 500) {
                    EventBus.dispatch("logout");
                }
            }
        );
    }

    const handleChangePassword = (formValue) => {
        const { oldPassword, newPassword } = formValue;

        dispatch(setProgress(progress + 50));
        AuthService.changeAccountPassword(oldPassword, newPassword, currentUser.user.id).then(
            (response) => {

                if (Object.keys(response.data.response_body.User).length > 0) {

                    setPageMessage("Password changed successfully");

                } else {
                    setPageMessage("Old password didn't not match.");
                }
                dispatch(setProgress(100));


            },
            (error) => {
                const _content =
                    (error.response &&
                        error.response.data &&
                        error.response.data.response_message) ||
                    error.message ||
                    error.toString();

                dispatch(setMessage(_content));
                dispatch(setProgress(100));


                if (error.response && error.response.status === 401) {
                    EventBus.dispatch("logout");
                }
                if (error.response && error.response.status === 500) {
                    EventBus.dispatch("logout");
                }
            }
        );
    }

    const handleUploadClick = () => {
        profileRef.current?.click();
    };

    const handleFileChange = (e) => {
        if (!e.target.files) {
            return;
        }

        dispatch(setProgress(progress + 50));
        UserService.fileUpload(e.target.files).then(
            (response) => {
                setLoading(false);

                setFile(response.data.response_body.File[0]);
                setPhotoUrl(response.data.response_body.File[0].url);
                dispatch(setProgress(100));
            },
            (error) => {
                const _content =
                    (error.response &&
                        error.response.data &&
                        error.response.data.response_message) ||
                    error.message ||
                    error.toString();
                setLoading(false);
                dispatch(setMessage(_content));
                dispatch(setProgress(100));
                if (error.response && error.response.status === 401) {
                    EventBus.dispatch("logout");
                }
                if (error.response && error.response.status === 500) {
                    EventBus.dispatch("logout");
                }

            }
        );

    };

    const handlePlanSubscription = (details, id, amount) => {
        UserService.addSubscription(currentUser.user.id, details.subscriptionID, id, amount).then(
            (response) => {

                if (response.data.response_body.Subscription) {
                    dispatch(setUserSubscriptions(response.data.response_body.Subscription))
                    setSubscriptionsSuccess(true)
                }
            },
            (error) => {
                const _content =
                    (error.response &&
                        error.response.data &&
                        error.response.data.response_message) ||
                    error.message ||
                    error.toString();

                dispatch(setMessage(_content));

                if (error.response && error.response.status === 401) {
                    EventBus.dispatch("logout");
                }
                if (error.response && error.response.status === 500) {
                    EventBus.dispatch("logout");
                }
            }
        );
    }

    const handleCancelSubscription = (subscriptionId) => {
        UserService.cancelSubscription(subscriptionId).then(
            (response) => {

                if (response.data.response_body.Subscription) {
                    setUserSubscriptions(response.data.response_body.Subscription)
                }
            },
            (error) => {
                const _content =
                    (error.response &&
                        error.response.data &&
                        error.response.data.response_message) ||
                    error.message ||
                    error.toString();

                dispatch(setMessage(_content));

                if (error.response && error.response.status === 401) {
                    EventBus.dispatch("logout");
                }
                if (error.response && error.response.status === 500) {
                    EventBus.dispatch("logout");
                }
            }
        );
    }

    const handleSocialMedidLinkUpdate = (formValue) => {

        formValue.paramLists.map(async (val, index) => {

            const { link, social_media } = val;

            await UserService.addOrUpdateSocialMediaLinks(
                link,
                social_media,
                currentUser.user.id,
                currentUser.user.id
            ).then(
                (response) => {
                    setLoading(false);


                },
                (error) => {
                    const _content =
                        (error.response &&
                            error.response.data &&
                            error.response.data.response_message) ||
                        error.message ||
                        error.toString();

                    setMessage(_content);
                    setLoading(false);

                    if (error.response && error.response.status === 401) {
                        EventBus.dispatch("logout");
                    }
                }
            );

        });


    }

    const handleUpdateUserSettings = (formValue) => {
        UserService.updateUserSettings(
            currentUser.user.id,
            formValue
        ).then(
            (response) => {
                setLoading(false);
                if (response.data.response_body.Settings) {
                    let userSettingsDetails = response.data.response_body.Settings;
                    setUserSettings(
                        {
                            notification_sms: userSettingsDetails.notification_sms,
                            notification_email: userSettingsDetails.notification_email
                        }
                    )
                }
            },
            (error) => {
                const _content =
                    (error.response &&
                        error.response.data &&
                        error.response.data.response_message) ||
                    error.message ||
                    error.toString();

                setMessage(_content);
                setLoading(false);

                if (error.response && error.response.status === 401) {
                    EventBus.dispatch("logout");
                }
            }
        );
    }

    const handleCreateApp = (formValue) => {
        const { name } = formValue;
        AuthService.createApps(
            name
        ).then(
            (response) => {
                setLoading(false);
                if (response.data.response_body.Project) {
                    let Project = response.data.response_body.Project;
                    setUserProjects([...userProjects, Project])
                }
            },
            (error) => {
                const _content =
                    (error.response &&
                        error.response.data &&
                        error.response.data.response_message) ||
                    error.message ||
                    error.toString();

                setMessage(_content);
                setLoading(false);

                if (error.response && error.response.status === 401) {
                    EventBus.dispatch("logout");
                }
            }
        );
    }

    const handleCreateEmailVerificationRequest = () => {

        dispatch(createEmailVerificationRequest({ userId: currentUser.user.id }))
            .unwrap()
            .then((originalPromiseResult) => {
                setPageMessage('Email verification request send to your registered email.')
            });

    }

    const handleDeleteAccount = useCallback(() => {

        dispatch(deleteAccount({ userId: currentUser.user.id }))
            .unwrap()
            .then(() => {
                setAccountDelete("Account delete request created successfully");
            });

    }, []);

    useEffect(() => {
        setPhotoUrl(account.photo);
    }, [account]);


    useEffect(() => {
        dispatch(clearMessage());

        dispatch(setProgress(progress + 50));

        Promise.all(
            [dispatch(getAccountByUserId({})),
            dispatch(getSubscription({ userId: currentUser.user.id })),
            dispatch(getSubscriptionHistory({ userId: currentUser.user.id })),
            dispatch(getSubscriptionPayments({ userId: currentUser.user.id })),
            dispatch(haveAccountMarkedForDeletion({ userId: currentUser.user.id })),
            ]);


        dispatch(setProgress(progress + 50));
        ManagePaymentService.getAllTransactionsByUserId(currentUser.user.id).then(
            (response) => {
                if (response.data.response_body.Payments) {
                    setMyTransactions(response.data.response_body.Payments)
                }
                dispatch(setProgress(100));
            },
            (error) => {
                const _content =
                    (error.response &&
                        error.response.data &&
                        error.response.data.response_message) ||
                    error.message ||
                    error.toString();

                dispatch(setMessage(_content));
                dispatch(setProgress(100));

                if (error.response && error.response.status === 401) {
                    EventBus.dispatch("logout");
                }
                if (error.response && error.response.status === 500) {
                    EventBus.dispatch("logout");
                }

            }
        );

        Promise.all(
            [
                UserService.getAllPlans(),
                UserService.getSocialMediaLinksByUserId(currentUser.user.id),
                UserService.getUserSettings(currentUser.user.id),
                AuthService.getApps(),
                UserService.getUserReferrals()
            ])
            .then((response) => {

                if (response[0].data.response_body.Plans) {
                    setPlans(response[0].data.response_body.Plans)
                }

                if (response[1].data.response_body.SocialMedia) {
                    let socialMedia = response[1].data.response_body.SocialMedia;
                    if (socialMedia.length > 0) {
                        setSocialMediaInitialValues({ paramLists: response[1].data.response_body.SocialMedia });
                    }
                }

                if (response[2].data.response_body.Settings) {
                    let userSettingsDetails = response[2].data.response_body.Settings;
                    setUserSettings(
                        {
                            notification_sms: userSettingsDetails.notification_sms,
                            notification_email: userSettingsDetails.notification_email
                        }
                    )
                }

                if (response[3].data.response_body.Projects) {
                    let projects = response[3].data.response_body.Projects;
                    setUserProjects(projects)
                }

                if (response[4].data.response_body.Referrals) {
                    let referrals = response[4].data.response_body.Referrals;
                    setUserReferrals(referrals)
                }

            }).catch((error) => {
                const _content =
                    (error.response &&
                        error.response.data &&
                        error.response.data.response_message) ||
                    error.message ||
                    error.toString();

                dispatch(setMessage(_content));

                if (error.response && error.response.status === 401) {
                    EventBus.dispatch("logout");
                }
                if (error.response && error.response.status === 500) {
                    EventBus.dispatch("logout");
                }
            });

    }, [dispatch]);

    if (!currentUser) {
        let formatReturnUrl = encodeURIComponent(`${searchParams.pathname}${searchParams.search}`);
        let returnUrl = `?returnUrl=${formatReturnUrl}`;
        return <Navigate to={`/login${returnUrl}`} />;
    }

    return (
        < >

            <main>
                <div className="cip-dashboard-body">
                    <NavigationBar props={{ type: 0, pageName: "Settings" }} />


                    <div className="support-tab-buttons-area">
                        <ul className="support-tab-buttons-container nav nav-pills" id="pills-tab" role="tablist">
                            <li className="nav-item" role="presentation">
                                <button className="nav-link active support-tab-buttons"
                                    data-bs-toggle="pill" data-bs-target="#pills-email-us" type="button" role="tab"
                                    aria-controls="pills-email-us" aria-selected="true">Personel</button>
                            </li>
                            <li className="nav-item" role="presentation">
                                <button className="nav-link support-tab-buttons" data-bs-toggle="pill"
                                    data-bs-target="#pills-paypal" type="button" role="tab" aria-controls="pills-contact"
                                    aria-selected="false">PayPal</button>
                            </li>
                            <li className="nav-item" role="presentation">
                                <button className="nav-link support-tab-buttons" data-bs-toggle="pill"
                                    data-bs-target="#pills-changePassword-tab" type="button" role="tab" aria-controls="pills-changePassword"
                                    aria-selected="false">Change Password</button>
                            </li>
                            <li className="nav-item" role="presentation">
                                <button className="nav-link support-tab-buttons" data-bs-toggle="pill"
                                    data-bs-target="#pills-myTransactions-tab" type="button" role="tab" aria-controls="pills-myTransactions"
                                    aria-selected="false">My Transactions</button>
                            </li>
                            <li className="nav-item" role="presentation">
                                <button className="nav-link support-tab-buttons" data-bs-toggle="pill"
                                    data-bs-target="#pills-plansAndSubscription-tab" type="button" role="tab" aria-controls="pills-plansAndSubscription"
                                    aria-selected="false">Plans & Subscription</button>
                            </li>
                            <li className="nav-item" role="presentation">
                                <button className="nav-link support-tab-buttons" data-bs-toggle="pill"
                                    data-bs-target="#pills-socialmedia-tab" type="button" role="tab" aria-controls="pills-socialmedia"
                                    aria-selected="false">Social media</button>
                            </li>
                            <li className="nav-item" role="presentation">
                                <button className="nav-link support-tab-buttons" data-bs-toggle="pill"
                                    data-bs-target="#pills-settings-tab" type="button" role="tab" aria-controls="pills-settings"
                                    aria-selected="false">Settings</button>
                            </li>
                            <li className="nav-item" role="presentation">
                                <button className="nav-link support-tab-buttons" data-bs-toggle="pill"
                                    data-bs-target="#pills-apps-tab" type="button" role="tab" aria-controls="pills-apps"
                                    aria-selected="false">Apps</button>
                            </li>
                            <li className="nav-item" role="presentation">
                                <button className="nav-link support-tab-buttons" data-bs-toggle="pill"
                                    data-bs-target="#pills-referral-tab" type="button" role="tab" aria-controls="pills-referral"
                                    aria-selected="false">Referral</button>
                            </li>
                            <li className="nav-item" role="presentation">
                                <button className="nav-link support-tab-buttons" data-bs-toggle="pill"
                                    data-bs-target="#pills-identityVerification-tab" type="button" role="tab" aria-controls="pills-identityVerification"
                                    aria-selected="false">Identity Verification</button>
                            </li>
                            <li className="nav-item" role="presentation">
                                <button className="nav-link support-tab-buttons" data-bs-toggle="pill"
                                    data-bs-target="#pills-accountDelete-tab" type="button" role="tab" aria-controls="pills-accountDelete"
                                    aria-selected="false">Account Delete</button>
                            </li>
                        </ul>
                    </div>

                    <div className="tab-content support-div-container" id="pills-tabContent">
                        <div className="tab-pane fade show active" id="pills-email-us" role="tabpanel"
                            aria-labelledby="support-email-us">
                            <div className='account-info-container'>
                                <div className="account-info-user-avatar">
                                    <div className={`${classes.container_image}`}>
                                        <img src={photoUrl ? photoUrl : "assets/img/user.png"} alt="user-avatar" />
                                        <div className={`${classes.overlay}`}>
                                            <a href="#" className={`${classes.icon}`} onClick={handleUploadClick} >
                                                <i className="fa fa-upload" aria-hidden="true"></i>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                <input type='file' style={{ display: 'none' }} ref={profileRef} onChange={handleFileChange} />
                                <div className='account-info-fields'>
                                    {message && (
                                        <div className="form-group">
                                            <div className="alert alert-danger" role="alert">
                                                {message}
                                            </div>
                                        </div>
                                    )}
                                    <div className='col-md-12'>
                                        {
                                            (account) &&
                                            (<Formik
                                                initialValues={{
                                                    firstName: account.firstName,
                                                    lastName: account.lastName,
                                                    date_of_birth: (account.date_of_birth ? moment(account.date_of_birth).format('yyyy-MM-DD') : ""),
                                                    phone: account.phone,
                                                    credits_earned: account.credits_earned
                                                }}
                                                validationSchema={Yup.object().shape({
                                                    firstName: Yup.string().required("This field is required!"),
                                                    lastName: Yup.string().required("This field is required!"),
                                                    phone: Yup.string()
                                                        .matches(/^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/, 'Phone number is not valid')
                                                        .min(10, "too short")
                                                        .max(10, "too long")
                                                })}
                                                onSubmit={handleAccountUpdate}
                                                enableReinitialize={true}
                                            >
                                                {({ values, errors, touched }) => (
                                                    <Form>
                                                        <div className="account-info-field-item">
                                                            <label className='text-title-3' htmlFor="firstName">First Name</label>
                                                            <div>
                                                                <Field
                                                                    name="firstName"
                                                                    type="text"
                                                                    className={
                                                                        "form-control" +
                                                                        (errors.firstName && touched.firstName ? " is-invalid" : "")
                                                                    }
                                                                />
                                                                <ErrorMessage
                                                                    name="firstName"
                                                                    component="div"
                                                                    className="invalid-feedback"
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="account-info-field-item">
                                                            <label className='text-title-3' htmlFor="lastName">Last Name</label>
                                                            <div>
                                                                <Field
                                                                    name="lastName"
                                                                    type="text"
                                                                    className={
                                                                        "form-control" +
                                                                        (errors.lastName && touched.lastName ? " is-invalid" : "")
                                                                    }
                                                                />
                                                                <ErrorMessage
                                                                    name="lastName"
                                                                    component="div"
                                                                    className="invalid-feedback"
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="account-info-field-item">
                                                            <label className='text-title-3' htmlFor="date_of_birth">Date Of Birth</label>
                                                            <div>
                                                                <Field
                                                                    name="date_of_birth"
                                                                    type="date"
                                                                    className={
                                                                        "form-control" +
                                                                        (errors.date_of_birth && touched.date_of_birth ? " is-invalid" : "")
                                                                    }
                                                                />
                                                                <ErrorMessage
                                                                    name="date_of_birth"
                                                                    component="div"
                                                                    className="invalid-feedback"
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="account-info-field-item">
                                                            <label className='text-title-3' htmlFor="phone">Phone</label>
                                                            <div>
                                                                <Field
                                                                    name="phone"
                                                                    type="text"
                                                                    className={
                                                                        "form-control" +
                                                                        (errors.phone && touched.phone ? " is-invalid" : "")
                                                                    }
                                                                />
                                                                <ErrorMessage
                                                                    name="phone"
                                                                    component="div"
                                                                    className="invalid-feedback"
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className='row account-info-buttons' style={{ marginTop: '10px' }}>
                                                            <button style={{ margin: '0 auto' }}
                                                                type="submit"
                                                                className="account-save-changes col-md-2"
                                                                disabled={loading}
                                                            >
                                                                {loading && (
                                                                    <span className="spinner-border spinner-border-sm"></span>
                                                                )}
                                                                <span>Submit</span>
                                                            </button>
                                                        </div>

                                                    </Form>
                                                )}
                                            </Formik>)}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="tab-pane fade show" id="pills-paypal" role="tabpanel"
                            aria-labelledby="support-email-us">
                            <div className='account-info-container'>
                                <div className="account-info-fields">
                                    {pageMessage && (
                                        <div className="form-group">
                                            <div className="alert alert-success" role="alert">
                                                {pageMessage}
                                            </div>
                                        </div>
                                    )}
                                    <Formik
                                        initialValues={{
                                            payout_email_address: account.payout_email_address,
                                            payout_payer_id: account.payout_payer_id
                                        }}
                                        onSubmit={handleUserPayPalDetails}
                                        enableReinitialize={true}
                                    >
                                        {({ values, errors, touched }) => (
                                            <Form>
                                                <div className="account-info-field-item">
                                                    <label className="text-title-3" htmlFor='payout_email_address' > Payout email address</label>
                                                    <div>
                                                        <Field
                                                            name="payout_email_address"
                                                            type="text"
                                                            readOnly
                                                            className={
                                                                (errors.payout_email_address && touched.payout_email_address ? " is-invalid" : "")
                                                            }
                                                        />
                                                        <ErrorMessage
                                                            name="payout_email_address"
                                                            component="div"
                                                            className="invalid-feedback"
                                                        />
                                                    </div>
                                                </div>
                                                <div className="account-info-field-item">
                                                    <label className="text-title-3" htmlFor='payout_payer_id' >Payout payer id</label>
                                                    <div>
                                                        <Field
                                                            name="payout_payer_id"
                                                            type="text"
                                                            readOnly
                                                            className={
                                                                (errors.payout_payer_id && touched.payout_payer_id ? " is-invalid" : "")
                                                            }
                                                        />
                                                        <ErrorMessage
                                                            name="payout_payer_id"
                                                            component="div"
                                                            className="invalid-feedback"
                                                        />
                                                    </div>
                                                </div>
                                            </Form>
                                        )}
                                    </Formik>
                                </div>
                            </div>
                        </div>
                        <div className="tab-pane fade show" id="pills-changePassword-tab" role="tabpanel"
                            aria-labelledby="support-email-us">
                            <div className="account-info-container">
                                <div className="account-info-fields">
                                    {pageMessage && (
                                        <div className="form-group">
                                            <div className="alert alert-success" role="alert">
                                                {pageMessage}
                                            </div>
                                        </div>
                                    )}
                                    <Formik
                                        initialValues={{
                                            oldPassword: "",
                                            newPassword: ""
                                        }}
                                        validationSchema={Yup.object().shape({
                                            oldPassword: Yup.string().required("This field is required!"),
                                            newPassword: Yup.string().required("This field is required!"),
                                        })}
                                        onSubmit={handleChangePassword}
                                        enableReinitialize={true}
                                    >
                                        {({ values, errors, touched }) => (
                                            <Form>
                                                <div className="account-info-field-item">
                                                    <label className='text-title-3' htmlFor="oldPassword">Old Password</label>
                                                    <div>
                                                        <Field
                                                            name="oldPassword"
                                                            type="password"
                                                            className={
                                                                (errors.oldPassword && touched.oldPassword ? " is-invalid" : "")
                                                            }
                                                        />
                                                        <ErrorMessage
                                                            name="oldPassword"
                                                            component="div"
                                                            className="invalid-feedback"
                                                        />
                                                    </div>
                                                </div>
                                                <div className="account-info-field-item">
                                                    <label className='text-title-3' htmlFor="newPassword">New Password</label>
                                                    <div>
                                                        <Field
                                                            name="newPassword"
                                                            type="password"
                                                            className={
                                                                (errors.newPassword && touched.newPassword ? " is-invalid" : "")
                                                            }
                                                        />
                                                        <ErrorMessage
                                                            name="newPassword"
                                                            component="div"
                                                            className="invalid-feedback"
                                                        />
                                                    </div>
                                                </div>
                                                <div className='row account-info-buttons' style={{ marginTop: '10px' }}>
                                                    <button style={{ margin: '0 auto' }}
                                                        type="submit"
                                                        className="account-save-changes col-md-2"
                                                        disabled={loading}
                                                    >
                                                        {loading && (
                                                            <span className="spinner-border spinner-border-sm"></span>
                                                        )}
                                                        <span>Submit</span>
                                                    </button>

                                                </div>

                                            </Form>
                                        )}
                                    </Formik>
                                </div>
                            </div>
                        </div>
                        <div className="tab-pane fade show" id="pills-myTransactions-tab" role="tabpanel"
                            aria-labelledby="support-email-us">
                            <div className="account-info-container">
                                <div className='table-responsive' style={{ width: '100%' }}>
                                    <table className="table">
                                        <thead className="thead-light">
                                            <tr>
                                                <th scope="col">Name</th>
                                                <th scope="col">Amount</th>
                                                <th scope="col">PayerId</th>
                                                <th scope="col">Betting Pool</th>
                                                <th scope="col">Payment Status</th>
                                                <th scope="col">created At</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {(myTransactions) && (myTransactions.length > 0) && myTransactions.map(function (nextItem, j) {
                                                return (
                                                    <tr key={nextItem.id}>
                                                        <td>{nextItem.User.firstName + " " + nextItem.User.lastName}</td>
                                                        <td>${nextItem.amount}</td>
                                                        <td>{nextItem.payer_id}</td>
                                                        <td><Link to={"/pool-details?id=" + nextItem.Pool.id} className='link-primary link-offset-2 link-underline-opacity-25 link-underline-opacity-100-hover'> {nextItem.Pool.name}</Link></td>
                                                        <td>{nextItem.status}</td>
                                                        <td>{moment(convertUTCDateToLocalDate(new Date(nextItem.createdAt))).format('MMMM Do YYYY, h:mm:ss a')}</td>
                                                    </tr>
                                                )
                                            })}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <div className="tab-pane fade show" id="pills-plansAndSubscription-tab" role="tabpanel"
                            aria-labelledby="support-email-us">
                            <div className="account-info-container">
                                <div className='col-12'>
                                    {(subscriptionsSuccess) && (
                                        <div className="alert alert-success" role="alert">
                                            We have successfully registered your subscription request .It will take few minutes to update details.
                                            Please return to this page after few minutes .
                                        </div>
                                    )}
                                    <br></br>
                                    <div className='row'>
                                        <ul className="nav nav-tabs" role="tablist">
                                            <li className="nav-item">
                                                <a className="nav-link active" data-bs-toggle="tab" href="#subscriptionDetails" role="tab" aria-controls="home" aria-selected="true">Subscription Details</a>
                                            </li>
                                            <li className="nav-item">
                                                <a className="nav-link" data-bs-toggle="tab" href="#subscriptionHistory" role="tab" aria-controls="profile" aria-selected="false">Subscription History</a>
                                            </li>
                                            <li className="nav-item">
                                                <a className="nav-link" data-bs-toggle="tab" href="#subscriptionPayment" role="tab" aria-controls="contact" aria-selected="false">Subscription Payments</a>
                                            </li>
                                        </ul>
                                        <div className="tab-content">
                                            <div className="tab-pane fade show active" id="subscriptionDetails" role="tabpanel" aria-labelledby="home-tab">
                                                <br></br>
                                                <div className="card">

                                                    {
                                                        (userSubscriptions) && (Object.keys(userSubscriptions).length !== 0) && (
                                                            <div className="card-body">
                                                                <div className='row'>
                                                                    <div className='col'>
                                                                        <div className="form-check">
                                                                            <label className="form-check-label" style={{ fontWeight: 'bold' }}>
                                                                                Plan name
                                                                            </label>
                                                                            <label className="form-check-label">
                                                                                {userSubscriptions.PoolsPlan.name}
                                                                            </label>
                                                                        </div>
                                                                        <div className="form-check">
                                                                            <label className="form-check-label" style={{ fontWeight: 'bold' }}>
                                                                                Plan type
                                                                            </label>
                                                                            <label className="form-check-label">
                                                                                {userSubscriptions.PoolsPlan.type}
                                                                            </label>
                                                                        </div>
                                                                    </div>
                                                                    <div className='col'>
                                                                        <div className="form-check">
                                                                            <label className="form-check-label" style={{ fontWeight: 'bold' }}>
                                                                                Plan price
                                                                            </label>
                                                                            <label className="form-check-label">
                                                                                ${userSubscriptions.PoolsPlan.price}
                                                                            </label>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className='row'>
                                                                    <div className='col'>
                                                                        <div className="form-check">
                                                                            <label className="form-check-label" style={{ fontWeight: 'bold' }}>
                                                                                Plan Start Date
                                                                            </label>
                                                                            <label className="form-check-label">
                                                                                {userSubscriptions.startDate}
                                                                            </label>
                                                                        </div>
                                                                        <div className="form-check">
                                                                            <label className="form-check-label" style={{ fontWeight: 'bold' }}>
                                                                                Plan Next Billing Date
                                                                            </label>
                                                                            <label className="form-check-label">
                                                                                {userSubscriptions.endDate}
                                                                            </label>
                                                                        </div>
                                                                    </div>
                                                                    <div className='col'>
                                                                        <div className="form-check">
                                                                            <label className="form-check-label" style={{ fontWeight: 'bold' }}>
                                                                                Plan status
                                                                            </label>
                                                                            <label className="form-check-label">
                                                                                {userSubscriptions.status}
                                                                            </label>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <br></br>
                                                                <br></br>
                                                                <div className='row'>
                                                                    <h3>{userSubscriptions.PoolsPlan.name}</h3>
                                                                    <div dangerouslySetInnerHTML={{ __html: userSubscriptions.PoolsPlan.description }} />
                                                                </div>
                                                                <br></br>
                                                                <div className='row'>

                                                                    {
                                                                        (userSubscriptions) && (userSubscriptions.status != 'CANCELLED') && (userSubscriptions.PoolsPlan.type !== 'FREE') &&
                                                                        (
                                                                            <Link className='col-2 btn btn-outline-warning' onClick={() => handleCancelSubscription(userSubscriptions.subscriptionId)}>Cancel Subscription</Link>
                                                                        )
                                                                    }
                                                                </div>
                                                            </div>
                                                        )
                                                    }
                                                </div>
                                            </div>
                                            <div className="tab-pane fade" id="subscriptionHistory" role="tabpanel" aria-labelledby="profile-tab">
                                                <div className='table-responsive' style={{ width: '100%' }}>
                                                    <table className="table">
                                                        <thead className="thead-light">
                                                            <tr>
                                                                <th scope="col">Name</th>
                                                                <th scope="col">Type</th>
                                                                <th scope="col">Status</th>
                                                                <th scope="col">created At</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {(userSubscriptionHistory) && (userSubscriptionHistory.length > 0) && userSubscriptionHistory.map(function (nextItem, j) {
                                                                return (
                                                                    <tr key={nextItem.id}>
                                                                        <td>{nextItem.PoolsPlan.name}</td>
                                                                        <td>{nextItem.summary}</td>
                                                                        <td>{nextItem.status}</td>
                                                                        <td>{nextItem.createdAt}</td>
                                                                    </tr>
                                                                )
                                                            })}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                            <div className="tab-pane fade" id="subscriptionPayment" role="tabpanel" aria-labelledby="contact-tab">
                                                <div className='table-responsive' style={{ width: '100%' }}>
                                                    <table className="table">
                                                        <thead className="thead-light">
                                                            <tr>
                                                                <th scope="col">Type</th>
                                                                <th scope="col">Status</th>
                                                                <th scope="col">created At</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {(userSubscriptionPayment) && (userSubscriptionPayment.length > 0) && userSubscriptionPayment.map(function (nextItem, j) {
                                                                return (
                                                                    <tr key={nextItem.id}>
                                                                        <td>{nextItem.summary}</td>
                                                                        <td>{nextItem.status}</td>
                                                                        <td>{nextItem.createdAt}</td>
                                                                    </tr>
                                                                )
                                                            })}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>




                                    </div>
                                    <br></br>
                                </div>
                            </div>
                            <div className="account-info-container">
                                <div className='row'>
                                    {
                                        (plans.length > 0) && plans?.map((item, index) => {
                                            return (
                                                <div className='row' style={{ margin: '5px' }} key={index}>
                                                    <div className='card'>
                                                        <br></br>
                                                        <h5 className="card-title"><label>{item.name}</label></h5>
                                                        <div className="card-body">
                                                            {
                                                                <div dangerouslySetInnerHTML={{ __html: item.description }} />
                                                            }
                                                            {
                                                                <h3>Price: ${item.price}/{item.type}</h3>
                                                            }
                                                            <div style={{ float: 'right', marginRight: '50px' }}>
                                                                {(userSubscriptions) && (userSubscriptions.planId !== item.id && item.type !== 'FREE') && (

                                                                    <div className='row'>
                                                                        {
                                                                            (paypal) && (<>
                                                                                <PaypalAppButton props={item} />
                                                                            </>
                                                                            )
                                                                        }
                                                                    </div>

                                                                )}

                                                                {
                                                                    (userSubscriptions) && (userSubscriptions.planId === item.id) && (
                                                                        <div style={{ textAlign: 'center' }}>
                                                                            <span className="badge badge-success">
                                                                                ACTIVE
                                                                            </span>
                                                                            <br></br>
                                                                            <br></br>
                                                                        </div>
                                                                    )
                                                                }

                                                                {
                                                                    (userSubscriptions) && (userSubscriptions.planId !== item.id) && (userSubscriptions.status != 'CANCELLED') && (item.type === 'FREE') && (
                                                                        <>
                                                                            <button className='btn btn-warning' onClick={() => handleCancelSubscription(userSubscriptions.subscriptionId)}>
                                                                                Subscribe
                                                                            </button>
                                                                            <br></br>
                                                                        </>
                                                                    )
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            );
                                        })
                                    }
                                    {

                                    }
                                </div>
                            </div>
                        </div>
                        <div className="tab-pane fade show" id="pills-socialmedia-tab" role="tabpanel">
                            <div className='account-info-container'>
                                <div className="account-info-fields">
                                    {pageMessage && (
                                        <div className="form-group">
                                            <div className="alert alert-success" role="alert">
                                                {pageMessage}
                                            </div>
                                        </div>
                                    )}
                                    <Formik
                                        initialValues={socialMediaInitialValues}
                                        onSubmit={handleSocialMedidLinkUpdate}
                                        enableReinitialize={true}                                >
                                        {({ values, errors, touched }) => (
                                            <Form>
                                                <FieldArray name="paramLists">
                                                    {({ insert, remove, push }) => (
                                                        <>
                                                            {values.paramLists.length > 0 &&
                                                                values.paramLists.map((social, index) => (
                                                                    <div className="row" key={index}>
                                                                        <div className="account-info-field-item">
                                                                            <label className='text-title-3' htmlFor={`paramLists.${index}.social_media`}>{social.social_media}</label>
                                                                            <div>
                                                                                <Field
                                                                                    name={`paramLists.${index}.link`}
                                                                                    type="text"
                                                                                    className={"form-control"}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                ))}
                                                            <div className='row account-info-buttons' style={{ marginTop: '10px' }}>
                                                                <button style={{ margin: '0 auto' }}
                                                                    type="submit"
                                                                    className="account-save-changes col-md-2"
                                                                    disabled={loading}
                                                                >
                                                                    {loading && (
                                                                        <span className="spinner-border spinner-border-sm"></span>
                                                                    )}
                                                                    <span>Submit</span>
                                                                </button>

                                                            </div>
                                                        </>
                                                    )}
                                                </FieldArray>
                                            </Form>
                                        )}
                                    </Formik>
                                </div>
                            </div>
                        </div>
                        <div className="tab-pane fade show" id="pills-settings-tab" role="tabpanel">
                            <div className='account-info-container'>
                                <div className="account-info-fields">
                                    {pageMessage && (
                                        <div className="form-group">
                                            <div className="alert alert-success" role="alert">
                                                {pageMessage}
                                            </div>
                                        </div>
                                    )}
                                    <Formik
                                        initialValues={userSettings}
                                        onSubmit={handleUpdateUserSettings}
                                        enableReinitialize={true}
                                    >
                                        {({ values, errors, touched }) => (
                                            <Form>
                                                <div className="account-info-field-item">
                                                    <label className="text-title-3" htmlFor='notification_sms' > Notification SMS</label>
                                                    <div>
                                                        <label>
                                                            <span>Off</span>&nbsp;
                                                            <Switch name='notification_sms' onChange={(e) => setUserSettings({ ...userSettings, notification_sms: e })} checked={values.notification_sms} />
                                                            <span>On</span>&nbsp;
                                                        </label>
                                                    </div>
                                                </div>
                                                <br></br>
                                                <br></br>
                                                <div className="account-info-field-item">
                                                    <label className="text-title-3" htmlFor='notification_email' > Notification Email</label>
                                                    <div>
                                                        <label>
                                                            <span>Off</span>&nbsp;
                                                            <Switch name='notification_email' onChange={(e) => setUserSettings({ ...userSettings, notification_email: e })} checked={values.notification_email} />
                                                            <span>On</span>&nbsp;
                                                        </label>
                                                    </div>
                                                </div>
                                                <br></br>
                                                <div className='row account-info-buttons' style={{ marginTop: '10px' }}>
                                                    <button style={{ margin: '0 auto' }}
                                                        type="submit"
                                                        className="account-save-changes col-md-2"
                                                        disabled={loading}
                                                    >
                                                        {loading && (
                                                            <span className="spinner-border spinner-border-sm"></span>
                                                        )}
                                                        <span>Submit</span>
                                                    </button>
                                                </div>
                                            </Form>
                                        )}
                                    </Formik>
                                </div>
                            </div>
                        </div>
                        <div className="tab-pane fade show" id="pills-apps-tab" role="tabpanel">
                            <div className='account-info-container' style={{ display: 'block' }}>
                                <div className='account-info-fields'>
                                    {message && (
                                        <div className="form-group">
                                            <div className="alert alert-danger" role="alert">
                                                {message}
                                            </div>
                                        </div>
                                    )}
                                    <div className='col-md-12'>
                                        <div className='row'>
                                            <div className='col-md-8'>
                                                <fieldset className="border p-2">
                                                    <legend className='float-none w-auto p-2'>My Apps</legend>
                                                    <div className="table-responsive">
                                                        <table className="table">
                                                            <thead className="thead-dark">
                                                                <tr>
                                                                    <th scope="col">Name</th>
                                                                    <th scope="col">ClientId</th>
                                                                    <th scope="col">ClientSecret</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {
                                                                    userProjects.map((proj, index) => {
                                                                        return (<tr key={index}>
                                                                            <td>{proj.name}</td>
                                                                            <td>{proj.client_id}</td>
                                                                            <td>{proj.client_secret}</td>
                                                                        </tr>);
                                                                    })
                                                                }
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </fieldset>
                                            </div>
                                            <div className='col-md-4'>
                                                <fieldset className="border p-2">
                                                    <legend className='float-none w-auto p-2'>Add</legend>
                                                    <Formik
                                                        initialValues={{
                                                            name: ''
                                                        }}
                                                        validationSchema={Yup.object().shape({
                                                            name: Yup.string().required("This field is required!")
                                                        })}
                                                        onSubmit={handleCreateApp}
                                                        enableReinitialize={true}
                                                    >
                                                        {({ values, errors, touched }) => (
                                                            <Form>
                                                                <div className="account-info-field-item col-6">
                                                                    <label className='text-title-3' htmlFor="name">Name</label>
                                                                    <div>
                                                                        <Field
                                                                            name="name"
                                                                            type="text"
                                                                            className={
                                                                                (errors.name && touched.name ? " is-invalid" : "")
                                                                            }
                                                                        />
                                                                        <ErrorMessage
                                                                            name="name"
                                                                            component="div"
                                                                            className="invalid-feedback"
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <div className='row account-info-buttons' style={{ marginTop: '10px' }}>
                                                                    <button style={{ margin: '0 auto' }}
                                                                        type="submit"
                                                                        className="account-save-changes col-md-2"
                                                                        disabled={loading}
                                                                    >
                                                                        {loading && (
                                                                            <span className="spinner-border spinner-border-sm"></span>
                                                                        )}
                                                                        <span>Submit</span>
                                                                    </button>

                                                                </div>

                                                            </Form>
                                                        )}
                                                    </Formik>
                                                </fieldset>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="tab-pane fade show" id="pills-referral-tab" role="tabpanel">
                            <div className='account-info-container' style={{ display: 'block' }}>
                                <div className='account-info-fields'>
                                    <div className='col-md-12'>
                                        <div className='row'>
                                            <h3 style={{ margin: 'auto', textAlign: 'center' }}>Share & Earn $5</h3>
                                        </div>
                                        <br></br>
                                        <div className='row'>
                                            <div className='col-8'>
                                                <fieldset className='border p-2'>
                                                    <legend className='float-none w-auto p-2' >Share your referral code</legend>
                                                    <div
                                                        style={{
                                                            height: "auto",
                                                            margin: "0 auto",
                                                            maxWidth: 150,
                                                            width: "100%",
                                                        }}
                                                    >
                                                        <QRCode
                                                            size={256}
                                                            style={{
                                                                height: "auto",
                                                                maxWidth: "100%",
                                                                width: "100%",
                                                            }}
                                                            value={
                                                                window.location.origin +
                                                                "/register?referral=" +
                                                                currentUser.user.referral_code
                                                            }
                                                            viewBox={`0 0 256 256`}
                                                        />
                                                    </div>
                                                    <br></br>
                                                    <CopyToClipboard
                                                        text={
                                                            window.location.origin +
                                                            "/register?referral=" +
                                                            currentUser.user.referral_code
                                                        }
                                                        onCopy={() => alert("Copied to clipboard !")}
                                                    ><button className="invite-member" style={{ margin: 'auto' }}>
                                                            <svg
                                                                width="17"
                                                                height="16"
                                                                viewBox="0 0 17 16"
                                                                fill="none"
                                                                xmlns="http://www.w3.org/2000/svg"
                                                            >
                                                                <path
                                                                    d="M9.73039 11.5101L7.55599 13.6845C6.22949 14.9755 4.10758 14.9468 2.81655 13.6203C1.55041 12.3194 1.55013 10.247 2.81589 8.94575L4.99029 6.76935C5.25057 6.50888 5.25042 6.08674 4.98995 5.82646C4.72948 5.56618 4.30734 5.56633 4.04706 5.8268L1.87331 8.0032C0.0441827 9.83327 0.0449326 12.7996 1.87497 14.6288C3.705 16.4579 6.67137 16.4571 8.50054 14.6271L10.6749 12.4527C10.9307 12.1879 10.9233 11.7659 10.6586 11.5102C10.4003 11.2607 9.9907 11.2607 9.73239 11.5102H9.73039V11.5101Z"
                                                                    fill="#C5914B"
                                                                />
                                                                <path
                                                                    d="M15.1292 1.37386C14.253 0.491678 13.0602 -0.00307683 11.8169 1.65573e-05C10.5743 -0.00329555 9.38186 0.490428 8.50528 1.3712L6.32753 3.54626C6.06706 3.80654 6.06691 4.22868 6.32719 4.48915C6.58747 4.74962 7.00961 4.74977 7.27008 4.48949L9.44648 2.31509C10.0733 1.68448 10.9264 1.33092 11.8155 1.3332C13.6666 1.33383 15.1667 2.83487 15.166 4.6859C15.1657 5.5743 14.8127 6.42626 14.1846 7.05453L12.0102 9.22894C11.7497 9.4894 11.7497 9.9117 12.0102 10.1722C12.2706 10.4326 12.6929 10.4326 12.9534 10.1722L15.1278 7.99908C16.9544 6.16854 16.955 3.20508 15.1292 1.37386Z"
                                                                    fill="#C5914B"
                                                                />
                                                                <path
                                                                    d="M10.029 5.52792L6.02943 9.52745C5.76461 9.78321 5.7573 10.2052 6.01305 10.47C6.2688 10.7348 6.69082 10.7421 6.9556 10.4864C6.96116 10.481 6.9666 10.4756 6.97197 10.47L10.9715 6.47047C11.2273 6.20566 11.2199 5.78367 10.9551 5.52792C10.6968 5.27845 10.2873 5.27845 10.029 5.52792Z"
                                                                    fill="#C5914B"
                                                                />
                                                            </svg>
                                                            Copy Link
                                                        </button>
                                                    </CopyToClipboard>
                                                </fieldset>
                                            </div>
                                            <div className='col-4'>
                                                <fieldset className='border p-2'>
                                                    <legend className='float-none w-auto p-2' >Credits</legend>
                                                    <div className='row' style={{ minHeight: '210px' }}>
                                                        <h2 style={{ margin: 'auto', textAlign: 'center', fontSize: 'xxx-large' }}>${account.credits_earned ? account.credits_earned : 0}</h2>
                                                    </div>
                                                </fieldset>
                                            </div>
                                        </div>
                                        <div className='row'>
                                            <fieldset className='border p-2'>
                                                <legend className='float-none w-auto p-2' >Referenced Users</legend>
                                                <div className='table-responsive' style={{ width: '100%' }}>
                                                    <table className="table">
                                                        <thead className="thead-light">
                                                            <tr>
                                                                <th scope="col">Name</th>
                                                                <th scope="col">Credits</th>
                                                                <th scope="col">Created At</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {(userReferrals) && (userReferrals.length > 0) && userReferrals.map(function (nextItem, j) {
                                                                return (
                                                                    <tr key={nextItem.id}>
                                                                        <td>{nextItem.User.firstName + " " + nextItem.User.lastName}</td>
                                                                        <td>${nextItem.credits}</td>
                                                                        <td>{moment(convertUTCDateToLocalDate(new Date(nextItem.createdAt))).format('MMMM Do YYYY, h:mm:ss a')}</td>
                                                                    </tr>
                                                                )
                                                            })}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </fieldset>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="tab-pane fade show" id="pills-identityVerification-tab" role="tabpanel">
                            <div className='account-info-container' style={{ display: 'block' }}>
                                <div className='account-info-fields'>
                                    <div className='col-md-12'>
                                        {pageMessage && (
                                            <div className="form-group">
                                                <div className="alert alert-success alert-dismissible fade show" role="alert">
                                                    {pageMessage}
                                                    <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                                                </div>
                                            </div>
                                        )}
                                        <table style={{ width: '100%' }}>
                                            <tbody>
                                                <tr>
                                                    <td>
                                                        <label>Email</label>
                                                    </td>
                                                    <td>
                                                        {(account &&
                                                            account.email_verified &&
                                                            account.email_verified === 1) ?
                                                            (<span className="badge text-bg-success">Verified</span>) :
                                                            (<button type="button" className="btn btn-outline-primary" onClick={handleCreateEmailVerificationRequest}>Verify</button>)
                                                        }

                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="tab-pane fade show" id="pills-accountDelete-tab" role="tabpanel">
                            <div className='account-info-container' style={{ display: 'block' }}>
                                <div className='account-info-fields'>
                                    {accountDelete && (
                                        <div className="form-group">
                                            <div className="alert alert-success" role="alert">
                                                {accountDelete}
                                            </div>
                                        </div>
                                    )}
                                    <div className='col-md-12'>
                                        <div className="form-group">
                                            {
                                                (accountDeleteRequests) && (accountDeleteRequests.length === 0) && (
                                                    <div className="alert alert-danger" role="alert">
                                                        <h4 className="alert-heading">Warning!</h4>
                                                        <p>
                                                            Deleting your account will remove all your data from the system. This action cannot be undone.
                                                        </p>
                                                        <hr />
                                                        <p className="mb-0">
                                                            <button type="button" className="btn btn-danger" onClick={handleDeleteAccount}>Delete Account</button>
                                                        </p>
                                                    </div>
                                                )
                                            }
                                            {
                                                (accountDeleteRequests) && (accountDeleteRequests.length > 0) && (
                                                    <div className="alert alert-danger" role="alert">
                                                        <h4 className="alert-heading">Warning!</h4>
                                                        <p>
                                                            Your account deletion request is pending. Please wait for the admin to approve your request.
                                                        </p>
                                                    </div>
                                                )
                                            }
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main >
        </>
    );
};

export default Account;
