import "./frontend.css";
import React, { useState, useEffect, useCallback, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Navigate,
  useNavigate,
  Link,
  useLocation,
  Outlet,
} from "react-router-dom";
import PoolService from "../../services/pool.service";
import EventBus from "../../common/eventBus";
import TimeAgo from "react-timeago";
import moment from "moment";
import Switch from "react-switch";
import { setHomeView } from "../../slices/general";
import { logout } from "../../slices/auth";
import Overlay from 'react-bootstrap/Overlay';

import $ from "jquery";
window.$ = window.jQuery = require("jquery");

const FrontendLayout = () => {
  let navigate = useNavigate();
  const searchParams = useLocation();
  const dispatch = useDispatch();
  const wrapperRef = useRef(null);
  const initialized = useRef(false)
  const { user: currentUser, isLoggedIn } = useSelector((state) => state.auth);
  const { headerNotificationUnRead } = useSelector((state) => state.chat);
  const { user: account } = useSelector((state) => state.user);
  const [view, setView] = useState(false);
  const [userNotifications, setUserNotifications] = useState([]);
  const [show, setShow] = useState(false);
  const target = useRef(null);


  useEffect(() => {
    if (!initialized.current) {
      initialized.current = true

      // Responsive Menu Toggle
      $('.responsive-menu-button button').length > 0 && $('.responsive-menu-button button').click(function () {
        $('.cip-navbar-dark').addClass('expanded')
        $('.cip-dashboard-body').addClass('responsive-menu-active')
        $('.responsive-menu-backdrop').addClass('active')
      })
      $('.responsive-menu-backdrop').click(function () {
        $('.cip-navbar-dark').removeClass('expanded')
        $('.cip-dashboard-body').removeClass('responsive-menu-active')
        $('.responsive-menu-backdrop').removeClass('active')
        $('.notification-area button').removeClass('active')
        $('.notifications-card').addClass('card-hidden')
      })
      $('.notification-area button').click(function () {
        $('.responsive-menu-backdrop').addClass('active')
      })

      // Joining request popup
      $('.joining-requests-area button').length > 0 && $('.joining-requests-area > button').click(function () {
        $('.joining-requests-area > button').toggleClass('active')
        $('.joining-requests-card').toggleClass('card-hidden')
      })

      // Pool create successful notice
      var newPoolButton = $('button.publish-new-pool')
      newPoolButton.length > 0 && newPoolButton.click(function () {
        $('.pool-created-succesfully').removeClass('hidden')
      })

      var closeSuccessNotice = $('.pool-create-success-notice button.close-notice')
      closeSuccessNotice.length > 0 && closeSuccessNotice.click(function () {
        $('.pool-created-succesfully').addClass('hidden')
      })

      // Pool invitation popup
      var invitationButton = $('button.invite-member')
      invitationButton.length > 0 && invitationButton.click(function () {
        $('.invite-pool-member-area').removeClass('hidden')
      })

      var closeInvite = $('.pool-invitation-header button')
      closeInvite.length > 0 && closeInvite.click(function () {
        $('.invite-pool-member-area').addClass('hidden')
      })

      // Password change email instruction
      var changePassButton = $('.account-info-buttons button.change-password')
      changePassButton.length > 0 && changePassButton.click(function () {
        $('.password-reset-email').removeClass('hidden')
      })

      // Joined pool successfully
      var poolJoinButton = $('.pool-fund-area button.pool-fund-button-join')
      poolJoinButton.length > 0 && poolJoinButton.click(function () {
        $('.pool-joined-succesfully').removeClass('hidden')
      })

      // Multiple Pool - Group goal amount
      var groupCloseButton = '<button><svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M12 4L4 12" stroke="#67717C" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" /><path d="M4 4L12 12" stroke="#67717C" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" /></svg></button>'

      $('button.group-goal-amount-button').click(function () {
        if ($("#groupName").val() && $("#goalAmount").val()) {
          var groupItem = $('<div class="group-item">').html('<p class="group-name text-body-2 m-0">' + $("#groupName").val() + '</p><div class="divider"></div><p class="group-goal-amount text-body-2 m-0">' + $("#goalAmount").val() + '</p>' + groupCloseButton)
          $('.group-badges').append(groupItem)
          $('.group-badges').css('margin-bottom', '12px');
        }
      })

    }
  }, [currentUser])


  useEffect(() => {
    if (currentUser) {
      PoolService.getNotificationsbyUserId(currentUser.user.id).then(
        (response) => {
          if (response.data.response_body.Notifications.length > 0) {
            setUserNotifications(response.data.response_body.Notifications);
          }
        },
        (error) => {
          if (error.response && error.response.status === 401) {
            EventBus.dispatch("logout");
          }

          if (error.response && error.response.status === 500) {
            EventBus.dispatch("logout");
          }
        }
      );
    }
  }, [currentUser]);

  const logOut = useCallback(() => {

    dispatch(logout())
      .unwrap().finally(() => {
        navigate("/login")
        window.location.reload();
      });

  }, [dispatch]);

  useEffect(() => {
    EventBus.on("logout", () => {
      logOut();
    });

    return () => {
      EventBus.remove("logout");
    };
  }, [logOut]);

  function utcToLocal(utcdateTime) {
    var localDateTime = moment(utcdateTime).local();
    return localDateTime;
  }

  const handleRedirect = (url) => {
    const action = new URLSearchParams(searchParams.search).get("action");
    const returnUrl = new URLSearchParams(searchParams.search).get("returnUrl");

    if (returnUrl) {
      return navigate(`${url}?returnUrl=${encodeURIComponent(returnUrl)}`);
    } else if (action === 'invited') {
      let invitedUrl = `${searchParams.pathname}${searchParams.search}`;
      return navigate(`${url}?returnUrl=${encodeURIComponent(invitedUrl)}`);
    }
    return navigate(`${url}`);
  }



  return (
    <>
      <header className="chipinpool-header">
        <nav
          className="navbar navbar-expand-lg bg-body-tertiary"
          style={{ width: "100%" }}
        >
          <div className="container-fluid">
            <Link className="navbar-brand" to="/">
              <img src="assets/img/logo-2.png" alt="logo" />
            </Link>
            <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarNavDropdown"
              aria-controls="navbarNavDropdown"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon"></span>
            </button>

            <div className="collapse navbar-collapse" id="navbarNavDropdown">
              <ul className="navbar-nav" style={{ margin: "auto" }}>
                {currentUser && (
                  <li className="nav-item">
                    <Link
                      to={"/dashboard"}
                      className="nav-link active"
                    >
                      Dashboard
                    </Link>
                  </li>
                )}
                <li className="nav-item">
                  <Link to={"/pools"} className="nav-link ">
                    Pools
                  </Link>
                </li>
                <li className="nav-item">
                  <Link to={"/events"} className="nav-link ">
                    Events
                  </Link>
                </li>
                <li className="nav-item">
                  <Link to={"/support"} className="nav-link ">
                    Support
                  </Link>
                </li>
              </ul>

              {!currentUser && (
                <ul className="navbar-nav">
                  <li className="nav-item">
                    <button onClick={() => handleRedirect("/register")} className="nav-link" >
                      <span className="glyphicon glyphicon-user"></span> Sign Up
                    </button>
                  </li>
                  <li className="nav-item">
                    <button onClick={() => handleRedirect("/login")} className="nav-link ">
                      <span className="glyphicon glyphicon-log-in"></span> Sign
                      In
                    </button>
                  </li>
                </ul>
              )}
              {currentUser && (
                <>
                  <i class="fa fa-bell-o" aria-hidden="true" ref={target} onClick={() => setShow(!show)} style={{ fontSize: '1.5rem' }}></i>
                  {
                    (headerNotificationUnRead.toString() !== "0")
                    && (<span className="notification-badge text-title-4">{headerNotificationUnRead}</span>)
                  }
                  <Overlay target={target.current} show={show} placement="bottom">
                    {({
                      placement: _placement,
                      arrowProps: _arrowProps,
                      show: _show,
                      popper: _popper,
                      hasDoneInitialMeasure: _hasDoneInitialMeasure,
                      ...props
                    }) => (<div
                      {...props}
                      style={{
                        position: 'absolute',
                        background: '#FFFFFF',
                        padding: '2px 10px',
                        borderRadius: '10px',
                        padding: '10px',
                        borderRadius: 3,
                        width: '500px',
                        ...props.style,
                      }}
                    >
                      <h4 className="text-title-2 text-center">Notifications</h4>
                      <ul className="notification-items mb-0">
                        {userNotifications?.map((item, index) => {
                          return (
                            <li key={index}>
                              <a href="#">
                                <p className="text-body-2 mb-0">{item.message}</p>
                                <TimeAgo
                                  date={utcToLocal(new Date(item.createdAt))}
                                  style={{ float: "right" }}
                                />
                              </a>
                            </li>
                          );
                        })}
                      </ul>
                    </div>)}
                  </Overlay>
                </>
              )}
              {currentUser && (
                <ul className="navbar-nav">
                  <li className="nav-item dropdown">
                    <a
                      className="nav-link dropdown-toggle"
                      href="#"
                      id="navbarUserDropdown"
                      role="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <img className="user-avatar" src={account.photo ? account.photo : "assets/img/user.png"} alt="user-avatar" />
                      {currentUser.user.username}
                    </a>
                    <ul
                      className="dropdown-menu"
                      aria-labelledby="navbarUserDropdown"
                    >
                      <li>
                        <Link to={"/account"} className="dropdown-item">
                          My Account
                        </Link>
                      </li>
                      <li>
                        <Link className="dropdown-item" onClick={logOut}>
                          Sign Out
                        </Link>
                      </li>
                    </ul>
                  </li>
                </ul>
              )}
            </div>
          </div>
        </nav>
      </header>
      <Outlet />
    </>
  );
};

export default FrontendLayout;
