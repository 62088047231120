import classes from './pool.module.css';
import React, { useState, useEffect, useRef, useCallback } from 'react';
import { Navigate, useNavigate, Link } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import useMediaQuery from "../../hooks/useMediaQuery";
import PoolService from "../../services/pool.service";
import EventBus from "../../common/eventBus";
import { setProgress } from "../../slices/loader";
import { renderToString } from 'react-dom/server';
import { clearMessage, setMessage } from "../../slices/message";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';
import Dropdown from 'react-bootstrap/Dropdown';




const Pool = (props) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { progress } = useSelector((state) => state.loader);
    const isMobile = useMediaQuery("(max-width: 768px)");
    const { user: currentUser } = useSelector((state) => state.auth);
    const wrapperRef = useRef([React.createRef()]);
    const row = props.pool;
    const i = props.index;
    const [goalPercentage, setGoalPercentage] = useState(0);


    useEffect(() => {
        let totalAmountInvested = props.pool.PoolsMembers.reduce((accumulator, current) => {
            return accumulator + parseFloat(current.contributed)
        }, 0);

        let amountPercentage = calculatePercentage(parseFloat(totalAmountInvested), parseFloat(props.pool.goal_amount));

        if (parseFloat(amountPercentage) > 100) {
            amountPercentage = 100;
        }

        setGoalPercentage(amountPercentage);
    }, [props.pool]);

    function calculatePercentage(partialValue, totalValue) {
        return (100 * partialValue) / totalValue;
    }

    const handlePoolSelection = function (pool) {
        var is_private = pool.is_private;

        if (is_private && currentUser) {
            var members = pool.PoolsMembers.filter(x => x.memberID == currentUser.user.id);
            if (members.length > 0) {
                navigate({
                    pathname: '/pool-details',
                    search: '?id=' + pool.id,
                });
            }
        } else {
            navigate({
                pathname: '/pool-details',
                search: '?id=' + pool.id,
            });
        }
    }

    const handleJoin = (PoolID) => {

        PoolService.addMemberToPool(currentUser.user.id, PoolID).then(
            (response) => {

                let Pools = response.data.response_body.Pools;

                navigate({
                    pathname: '/pool-details',
                    search: '?id=' + PoolID,
                });

            },
            (error) => {
                const _content =
                    (error.response &&
                        error.response.data &&
                        error.response.data.response_message) ||
                    error.message ||
                    error.toString();

                if (error.response && error.response.status === 401) {
                    EventBus.dispatch("logout");
                }
                if (error.response && error.response.status === 500) {
                    EventBus.dispatch("logout");
                }

            }
        );
    }

    const handleInviteSelection = function (pool) {
        var members = pool.PoolsMembers.filter(x => x.memberID == currentUser.user.id);
        if (members.length > 0) {
            navigate({
                pathname: '/pool-details',
                search: '?id=' + pool.id,
            });
        }
        window.location.reload();
    }

    const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
        <a
            href=""
            ref={ref}
            onClick={(e) => {
                e.preventDefault();
                onClick(e);
            }}
        >
            {children}
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M12 20.125C11.45 20.125 10.9793 19.9293 10.588 19.538C10.196 19.146 10 18.675 10 18.125C10 17.575 10.196 17.104 10.588 16.712C10.9793 16.3207 11.45 16.125 12 16.125C12.55 16.125 13.021 16.3207 13.413 16.712C13.8043 17.104 14 17.575 14 18.125C14 18.675 13.8043 19.146 13.413 19.538C13.021 19.9293 12.55 20.125 12 20.125ZM12 14.125C11.45 14.125 10.9793 13.929 10.588 13.537C10.196 13.1457 10 12.675 10 12.125C10 11.575 10.196 11.104 10.588 10.712C10.9793 10.3207 11.45 10.125 12 10.125C12.55 10.125 13.021 10.3207 13.413 10.712C13.8043 11.104 14 11.575 14 12.125C14 12.675 13.8043 13.1457 13.413 13.537C13.021 13.929 12.55 14.125 12 14.125ZM12 8.125C11.45 8.125 10.9793 7.929 10.588 7.537C10.196 7.14567 10 6.675 10 6.125C10 5.575 10.196 5.10433 10.588 4.713C10.9793 4.321 11.45 4.125 12 4.125C12.55 4.125 13.021 4.321 13.413 4.713C13.8043 5.10433 14 5.575 14 6.125C14 6.675 13.8043 7.14567 13.413 7.537C13.021 7.929 12.55 8.125 12 8.125Z" fill="#949A9D"></path>
            </svg>
        </a>

    ));

    const CustomMenu = React.forwardRef(
        ({ children, style, className, 'aria-labelledby': labeledBy }, ref) => {
            const [value, setValue] = useState('');

            return (
                <div
                    ref={ref}
                    style={style}
                    className={className}
                    aria-labelledby={labeledBy}
                >

                    <ul className="list-unstyled">
                        {React.Children.toArray(children).filter(
                            (child) =>
                                !value || child.props.children.toLowerCase().startsWith(value),
                        )}
                    </ul>
                </div>
            );
        },
    );


    return (
        <div className={`pool-card publicPools ${row.status == 0 ? "closedPools" : "openPools"}`}>
            <div className="pool-item" >
                <div className="pool-thumbnail">
                    <img src={row.photo ? row.photo : "assets/img/pool-thumbnails/pool-2.png"} alt="pool-thumbnail" />
                </div>
                <div className="d-block w-100">
                    <div className="pool-elements">
                        <div className="pool-header">
                            <div className="pool-title-badge">
                                <h3 className="text-title-2" style={{ wordWrap: 'break-word', inlineSize: '150px' }}><a href="#" onClick={() => handlePoolSelection(row)}>{row.name}</a></h3>
                                <div className="pool-badges" style={{ display: 'table-row' }}>
                                    {
                                        ((Object.keys(row).length > 0)) && (row.is_private == 1) && (
                                            <span className="badge bg-warning" style={{ height: 'fit-content', marginRight: 'auto', marginLeft: '7px', marginTop: '5px' }}>Private</span>
                                        )
                                    }
                                    {
                                        ((Object.keys(row).length > 0)) && (row.is_private == 0) && (
                                            <span className="badge bg-warning" style={{ height: 'fit-content', marginRight: 'auto', marginLeft: '7px', marginTop: '5px' }}>Public</span>
                                        )
                                    }
                                    {
                                        (Object.keys(row).length > 0) && (currentUser) && (row.PoolsMembers.length > 0) &&
                                        (row.PoolsMembers.filter(member => member.memberID === currentUser.user.id).length > 0) && (
                                            <span className="badge bg-success" style={{ height: 'fit-content', marginRight: 'auto', marginLeft: '7px', marginTop: '5px' }}>Joined</span>
                                        )
                                    }
                                    {
                                        ((Object.keys(row).length > 0)) && (currentUser) && (row.ownerID == currentUser.user.id) && (
                                            <span className="badge bg-success" style={{ height: 'fit-content', marginLeft: '7px', marginTop: '5px' }}>Commissioner</span>
                                        )
                                    }
                                    {
                                        (Object.keys(row).length > 0) && (currentUser) && (row.ownerID == currentUser.user.id) && (
                                            <span className="badge bg-success" style={{ height: 'fit-content', marginRight: 'auto', marginLeft: '7px', marginTop: '5px' }}>Owner</span>
                                        )
                                    }

                                </div>
                            </div>
                            {
                                (Object.keys(row).length > 0)
                                && (currentUser)
                                && (row.status == 1)
                                && (
                                    <Dropdown>
                                        <Dropdown.Toggle as={CustomToggle}>
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu as={CustomMenu}>
                                            {(row.PoolsMembers.length > 0)
                                                && (row.PoolsMembers.filter(member => member.memberID === currentUser.user.id).length > 0) && (
                                                    <Dropdown.Item href='#' onClick={() => handleInviteSelection(row)}>Invite Members</Dropdown.Item>
                                                )}

                                            {(!row.is_private)
                                                && ((row.PoolsMembers.length >= 0)
                                                    && (row.PoolsMembers.filter(member => member.memberID === currentUser.user.id).length == 0))
                                                && (
                                                    <Dropdown.Item href='#' onClick={() => handleJoin(row.id)}>Join</Dropdown.Item>
                                                )
                                            }

                                            {
                                                (Object.keys(row).length > 0)
                                                && (row.status == 1)
                                                && (row.is_private)
                                                && (
                                                    <Dropdown.Item href='#' data-bs-toggle="modal" data-bs-target="#JoinPrivateNotification">Join</Dropdown.Item>
                                                )
                                            }
                                        </Dropdown.Menu>
                                    </Dropdown>
                                )
                            }

                        </div>
                        <div className="pool-members">
                            <div className="pool-member-text text-body-3">{row.PoolsMembers.length} of {(row.PoolsSettings.length > 0) && (row.PoolsSettings[0].maxMembers)} Members</div>
                        </div>
                    </div>
                </div>

            </div>
            <div className="pool-footer">
                <div className="pool-currency d-flex justify-content-between">
                    <h4 className="text-title-4">{row.PoolsMembers.length > 0 ? "$" + row.PoolsMembers.reduce((accumulator, current) => { return accumulator + parseFloat(current.contributed); }, 0) : "$0"}</h4>
                    <h4 className="text-body-3">${row.goal_amount}</h4>
                </div>
                <div className="pool-progressbar">
                    <div className="pool-progress" style={{ width: `${goalPercentage}%` }}></div>
                </div>
                <br></br>
                <div className="modal fade" id="JoinPrivateNotification" tabIndex="-1" aria-labelledby="JoinPrivateNotification" aria-hidden="true">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" >Alert!</h5>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div className="modal-body">
                                Private pools are joined by invitaion from pool members. You have to be member of pool in order to view it.
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>);

};

export default Pool;